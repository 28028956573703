@import 'variables';
@import 'mixins';

.modalContainer {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.6rem;
		padding: 2.4rem;
		border-bottom: 1px solid var(--border-tertiary-color);

		& > button {
			padding: unset;
		}
	}

	.content {
		display: flex;
		width: 840px;

		@media screen and (max-width: 950px) {
			width: 100%;
		}

		& > div {
			max-height: 500px;
			overflow: auto;
		}

		.namesSection {
			display: flex;
			flex-direction: column;
			overflow: auto;
			background-color: #f6f7f9;
			width: 220px;
		}

		.editSection {
			padding: 1.6rem 2.4rem 2.4rem 2.4rem;
			display: flex;
			flex-direction: column;
			gap: 2.4rem;
			flex: 1;

			.inputContainer {
				display: flex;
				flex-direction: column;

				.label {
					& > p {
						margin-top: 0.6rem;
						line-height: 1.6rem;
					}
				}
			}
		}
	}
}

.addBtn {
	padding: unset;
	display: flex;
	gap: 0.6rem;
	align-items: center;
	border-radius: 0px;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			& > span {
				& > svg path {
					stroke: var(--text-primary-color);
				}
			}
			& > h3 {
				color: var(--text-primary-color);
			}
		}
	}
}

.name {
	padding: 1.6rem 1.6rem 1.6rem 1.2rem;
	border-left: 4px solid transparent;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
			& > h3 {
				color: var(--text-primary-color);
			}
		}
	}
}

.input {
	width: 100%;
	outline: none;
	border: 1px solid var(--border-secondary-color);
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.8rem;
	padding: 1.2rem 1.6rem;
	background-color: transparent;
	border-radius: 12px;
	margin-top: 1.2rem;
	margin-bottom: 0.4rem;

	&::placeholder {
		font-weight: 400;
		color: var(--text-tertiary-color-v0);
	}
}

.textAreaInput {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.8rem;
	@extend .input;
	height: 170px;
	resize: none; /* Prevent manual resizing */

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		font-weight: 400;
		color: var(--text-tertiary-color-v0);
	}
}

.btnsContainer {
	display: flex;
	gap: 2.4rem;
	align-items: center;
	justify-content: flex-end;

	.deleteBtn {
		padding: 0;
		border-radius: 8px;
		align-items: center;
		gap: 0.6rem;
		min-height: 4rem;
	}

	.addSaveBtn {
		border-radius: 8px;
		padding: 0.8rem 1.6rem;
		align-items: center;
		gap: 0.8rem;
		min-height: 4rem;
		min-width: 96px;
	}
}

.selectedMode {
	background-color: var(--background-blue-secondary-color);
	border-left: 4px solid var(--brand-primary-color);

	& > span {
		& > svg path {
			stroke: var(--text-primary-color);
		}
	}
	& > h3 {
		color: var(--text-primary-color);
	}
}

.deleteAlertModalContentContainer {
	width: 100%;
	max-width: 420px;

	& > p {
		margin-top: 2.4rem;
	}
}

.bookmarkActionContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.6rem;
	width: 100%;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	.checkbox {
		height: 1.6rem;
		width: 1.6rem;
		border: 1.5px solid var(--brand-primary-color);
		background-color: var(--background-primary-color);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		background-color: var(--brand-primary-color);
	}

	& > p {
		line-height: 2rem;
		width: 100%;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	gap: 1.6rem;
}

.modalCancelBtn {
	padding: auto 2.8rem;
	height: 44px;
	width: 100%;
	margin-top: 2.4rem;
	border: 1px solid var(--border-secondary-color);
	border-radius: 0.8rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.modalDeleteBtn {
	padding: auto 2.8rem;
	height: 44px;
	width: 100%;
	margin-top: 2.4rem;
	background-color: var(--error-color);
	border-radius: 0.8rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--error-hover-color);
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.successStateBtn {
	background-color: var(--success-color);
}
