@import 'variables';

.labelsListContainerWrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.loadingContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--background-primary-color);
}

.labelsListContainer {
	flex: 1;
	overflow-y: auto;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-top: 1.6rem;
	}
}

.labelSelectItem {
	height: 50px;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	display: flex;
	align-items: center;

	& > div:nth-child(1) {
		align-items: center;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
		height: 42px;

		& > div:nth-child(1) {
			align-items: start;
		}
	}
}

.addNewLabelBtn {
	gap: 0.8rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	justify-content: flex-start;
	height: 50px;
	border-top: 1px solid transparent;

	@media screen and (max-width: $mobile-screen-max-width) {
		border-top: 1px solid #e7f1f9;
		justify-content: center;
		width: calc(100% - 3.2rem);
		margin: 1.6rem 1.6rem 0 1.6rem;
		padding-top: 1.6rem;
		padding-bottom: 2.4rem;
		height: 64px;
	}

	& > span:nth-child(1) {
		& > svg path {
			stroke: var(--brand-primary-color);
		}
	}
}

.addNewLabelBtnWithBorder {
	border-top: 1px solid #e7f1f9;
}
