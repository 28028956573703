@import 'variables';
@import 'mixins';

.overviewContainer {
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	margin-bottom: 2.4rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		gap: 2rem;
	}
}

.insightsWithTopPicksWrapper {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	position: relative;

	& > h4 {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}
}

.insightsWithTopPicksSection {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 1.2rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.chevronLeft {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	width: 24px;
	opacity: 0;
	transition: opacity 0.1s linear;
	z-index: 1;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 12.5%, #ffffff 58.33%);
	transform: rotate(180deg);

	& > button {
		border: 1px solid var(--background-tertiary-color);
		background-color: var(--background-primary-color);
		border-radius: 999px;
		width: 2.4rem;
		height: 2.4rem;
		margin-left: -0.6rem;
		box-shadow: 0px 4px 12px 0px #2b333b1a;

		.icon {
			& > path {
				background: #7b8c9e;
			}
		}
	}
}

.chevronLeftCardView {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 12.5%, #f8fbfe 58.33%);
}

.chevronRight {
	@extend .chevronLeft;
	left: unset;
	right: 0;
	justify-content: flex-end;
	transform: unset;

	& > button {
		margin-left: unset;
		margin-right: 0.6rem;
	}
}

.chevronRightCardView {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 12.5%, #f8fbfe 58.33%);
}

.showChevron {
	visibility: visible;
	opacity: 1;
}
