.svgWrapper {
	display: inline-block;
	height: fit-content;
	width: fit-content;
	line-height: 0;
}

.disabled path {
	fill: var(--text-disabled-color);
}
