@import 'variables';
@import 'mixins';

$connectLinkedInContainerHeight: 148px;
$connectLinkedInContainerHeightMobile: 142px;

.connectLinkedInContainer {
	height: 0;
	overflow: hidden;
	position: relative;

	input {
		caret-color: var(--text-primary-color);
	}
}

.connectLinkedInContainerLoading {
	input {
		caret-color: var(--background-primary-color);
	}
}

.animateConnectLinkedInContainer {
	animation: slideUp 0.3s ease-in-out 0.4s forwards;

	@media screen and (max-width: $mobile-screen-max-width) {
		animation: slideUpMobile 0.3s ease-in-out 0.4s forwards;
	}
}

.connectLinkedInContainerHeight {
	height: $connectLinkedInContainerHeight;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: $connectLinkedInContainerHeightMobile;
	}
}

@keyframes slideUp {
	from {
		height: 0px;
	}
	to {
		height: $connectLinkedInContainerHeight;
	}
}

@keyframes slideUpMobile {
	from {
		height: 0px;
	}
	to {
		height: $connectLinkedInContainerHeightMobile;
	}
}

.loadingContainer {
	position: absolute;
	height: $connectLinkedInContainerHeight;
	width: 100%;
	background-color: var(--background-primary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		height: $connectLinkedInContainerHeightMobile;
	}

	.profileContainerLoading {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 1.2rem;
		row-gap: 0.4rem;
		grid-template-areas: 'profilePic profileName' 'profilePic profileHeadline';
		opacity: 0;
		animation: fadeInOut 1.25s ease-in-out infinite;
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 2rem;
			margin-bottom: 2.2rem;
		}

		& > div {
			background-color: var(--background-secondary-color);
		}

		.profileImage {
			grid-area: profilePic;
			height: 4.8rem;
			width: 4.8rem;
			border-radius: 9999px;
		}

		.profileName {
			grid-area: profileName;
			width: 106px;
			height: 8px;
			border-radius: 9999px;
			align-self: center;
		}

		.profileHeadline {
			grid-area: profileHeadline;
			width: 60px;
			height: 8px;
			border-radius: 9999px;
			align-self: center;
		}
	}
}

@keyframes fadeInOut {
	from {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.inputContentContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;

	.inputContentHeader {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 0.6rem;
		grid-template-areas: 'header helpInfo' 'subtext helpInfo';
		width: 100%;

		@media screen and (max-width: $mobile-screen-max-width) {
			gap: 0.4rem;
		}

		h2 {
			grid-area: header;
			@include textEllipsis();
		}

		p {
			grid-area: subtext;
			@include textEllipsis();
		}

		button {
			grid-area: helpInfo;
			padding: unset;
			align-self: flex-end;
			gap: 0.2rem;

			.helpText {
				text-decoration: underline;
			}
		}
	}

	.input {
		flex: 1;
		outline: none;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 500;
		background-color: transparent;
		width: 100%;
		padding: 1.2rem;
		min-height: 42px;
		max-height: 42px;
		border: 1px solid var(--text-primary-color);
		border-radius: 0.6rem;
		margin-top: 2rem;
		margin-bottom: 1.2rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 1.6rem;
			margin-bottom: 1.2rem;
		}

		&::placeholder {
			color: var(--text-tertiary-color);
			font-weight: 300;
		}

		&:focus-visible {
			outline: none;
		}
	}

	.inputContentFooter {
		display: flex;
		gap: 1.6rem;
		width: 100%;

		& > div:nth-child(1) {
			flex: 1;

			.errorText {
				@include textEllipsis();
				height: 16px;
			}

			.openLinkedInBtn {
				padding: unset;
				margin-top: 0.8rem;
			}

			.hideBtn {
				visibility: hidden;
			}
		}

		.connectBtn {
			padding: unset;
			height: 32px;
			width: 88px;
			min-width: 88px;
			max-width: 88px;
			border-radius: 0.6rem;

			@media screen and (max-width: $mobile-screen-max-width) {
				max-width: unset;
			}
		}
	}
}

.profileContentContainer {
	& > h2 {
		@include textEllipsis();
	}

	.profileContainer {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 1.2rem;
		row-gap: 0.4rem;
		grid-template-areas: 'profilePic profileName' 'profilePic profileHeadline';
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 2rem;
			margin-bottom: 2.2rem;
		}

		.profileImage {
			grid-area: profilePic;
		}

		& > h3 {
			grid-area: profileName;
			align-self: flex-end;
			@include textEllipsis();
		}

		& > p {
			grid-area: profileHeadline;
			@include textEllipsis();
		}
	}

	.noHeadline {
		grid-template-areas: 'profilePic profileName';
		align-items: center;
	}

	.buttonsContainer {
		display: flex;
		align-items: center;
		gap: 1.2rem;
		width: 100%;

		.cancelBtn {
			border-radius: 6px;
			padding: unset;
			height: 32px;
			width: 100%;
			border: 1px solid var(--border-secondary-color);
		}

		.confirmBtn {
			padding: unset;
			height: 32px;
			width: 100%;
			border-radius: 6px;
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
