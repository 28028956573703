.horizontalViewContainer {
	display: flex;
	align-items: center;
	overflow: auto;
	flex-wrap: nowrap;
	gap: 0.8rem;
	width: 0;
	margin-left: auto;
	margin-right: auto;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.justifyContentEnd {
	& > button:nth-child(1) {
		margin-left: auto;
	}
}

.cannedResponse {
	border-radius: 9999px;
	border: 1px solid var(--border-secondary-color);
	height: 32px;
	background-color: white;
	padding: 0.8rem 1.2rem;
}

.likeCannedResponse {
	@extend .cannedResponse;
}

.addCannedResponseBtn {
	@extend .cannedResponse;
	gap: 0.4rem;
	padding: 0.8rem 1.2rem 0.8rem 1rem;
}

.cannedResponseAddIcon {
	path {
		stroke: var(--text-blue-primary-color);
		fill: var(--text-blue-primary-color);
	}
}
