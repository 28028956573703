@import 'mixins';

.introMessageBox {
	height: 280px;
	width: 100%;
	background-color: var(--background-secondary-color);
	padding: 1.6rem;
	overflow: auto;
	border-radius: 0.8rem;
	border: 1px solid var(--background-secondary-color);
	white-space: pre-wrap;
	margin-bottom: 0.6rem;

	& > a {
		color: inherit;
		text-decoration: underline;
	}
}

.introMessageContainer {
	.input {
		@include fontFamily();
		@extend .introMessageBox;
		background-color: unset;
		flex: 1;
		background-color: transparent;
		cursor: auto;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
		resize: none; /* Prevent manual resizing */
		border: 1px solid var(--border-secondary-color);
		caret-color: var(--text-primary-color);

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: var(--text-tertiary-color-v0);
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
