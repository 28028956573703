@import 'mixins';
@import 'variables';

.modalContainer {
	& > div:nth-child(1) {
		height: 500px;

		& > div:nth-child(1) {
			height: 100%;
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.6rem;
		padding: 2.4rem 2.4rem 1.2rem 2.4rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 2.4rem 1.6rem 1.2rem 1.6rem;
		}

		& > button {
			padding: unset;
		}
	}

	.content {
		width: 720px;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		@media screen and (max-width: 750px) {
			width: 100%;
		}

		.mailsMainContainer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 1.2rem 2.4rem 1.6rem 2.4rem;
			cursor: text;

			@media screen and (max-width: $mobile-screen-max-width) {
				padding-left: 1.6rem;
				padding-right: 1.6rem;
				padding-top: 0.8rem;
			}

			& > h3 {
				margin: 0.4rem;
				margin-left: unset;
				margin-right: 1.2rem;
				white-space: nowrap;
				align-self: flex-start;
			}

			.mailsContainer {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				gap: 0.8rem;
				width: 100%;

				.mailContainer {
					border: 1px solid var(--border-secondary-color);
					display: flex;
					align-items: center;
					width: fit-content;
					padding: 0.6rem 0.8rem;
					border-radius: 8px;
					gap: 0.6rem;

					.closeBtn {
						padding: unset;
					}
					@media screen and (min-width: $mobile-screen-max-width) {
						&:hover {
							background-color: var(--background-secondary-color);
						}
					}
				}

				.input {
					@include fontFamily();
					flex: 1;
					outline: none;
					font-size: 1.4rem;
					font-weight: 400;
					line-height: 1.8rem;
					background-color: transparent;
					border: none;
					height: 32px;

					&::placeholder {
						color: var(--text-tertiary-color-v0);
					}
				}
			}
		}

		.textContainer {
			@include fontFamily();
			color: var(--text-primary-color);
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			resize: none;
			border: none;
			height: auto;
			min-height: 200px;
			border-top: 1px solid var(--border-secondary-color);
			border-bottom: 1px solid var(--border-secondary-color);
			padding: 1.6rem 2.4rem;
			flex: 1;

			@media screen and (max-width: $mobile-screen-max-width) {
				padding-left: 1.6rem;
				padding-right: 1.6rem;
			}

			&:focus-visible {
				outline: none;
			}
		}

		.attachmentsContainer {
			display: flex;
			flex-wrap: wrap;
			gap: 0.6rem;
			margin-top: 1.6rem;
			padding-left: 2.4rem;
			padding-right: 2.4rem;

			@media screen and (max-width: $mobile-screen-max-width) {
				padding-left: 1.6rem;
				padding-right: 1.6rem;
			}

			.selectedAttachmentContainer {
				display: flex;
				align-items: center;
				width: fit-content;
				padding: 0.6rem 0.8rem;
				background-color: var(--background-secondary-color);
				border-radius: 8px;
				max-width: 160px;
				gap: 0.6rem;

				.name {
					@include textEllipsis();
				}

				.closeBtn {
					padding: unset;
				}

				@media screen and (min-width: $mobile-screen-max-width) {
					&:hover {
						background-color: var(--background-tertiary-color);
					}
				}
			}
		}
	}

	.footer {
		padding: 2.4rem;
		padding-top: unset;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 1.6rem;
			padding-top: unset;
		}

		.sendBtn {
			margin-top: 2.4rem;
			width: 200px;
			border-radius: 8px;
			height: 44px;
			margin-left: auto;

			@media screen and (max-width: $mobile-screen-max-width) {
				width: 100%;
				margin-left: unset;
				margin-top: 1.6rem;
			}
		}
	}
}

.toastIcon {
	& path {
		stroke: var(--background-primary-color);
	}
}

.emailSuggestionContent {
	position: absolute;
	background-color: var(--background-primary-color);
	border-radius: 0.8rem;
	border: 1px solid var(--border-tertiary-color);
	height: fit-content;
	max-height: 250px;
	width: max-content;
	max-width: 400px;
	margin-top: 0.2rem;
	overflow: auto;
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	z-index: 9999;
	display: none;
}

.displayEmailSuggestionContent {
	display: block;
}

.suggestionEmail {
	padding: 1rem 2rem;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 1.6rem;
	align-items: center;
}

.suggestionEmailSelected {
	background-color: var(--background-secondary-color);
}

.suggestionEmailText {
	@include textEllipsis();
}
