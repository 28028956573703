@import 'mixins';

.editSendViewContainer {
	display: flex;
	flex-direction: column;
	background-color: var(--background-primary-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		width: 420px;
		padding: 2.4rem;
	}
}

.mainHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2.4rem;
	margin: 2.4rem 2.4rem 1.6rem 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		margin: 0 0 1.6rem 0;
	}

	& > h2 {
		flex: 1;
		line-height: 24px;
	}

	.closeIconBtn {
		padding: unset;

		.closeIcon {
			path {
				stroke: var(--text-tertiary-color);
			}
		}
	}
}

.contentEditMode {
	flex: 1;
	overflow: auto;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		margin-left: 0;
		margin-right: 0;
	}

	.textAreaInput {
		width: 100%;
		outline: none;
		border: 1px solid var(--border-secondary-color);
		padding: 1.2rem 1.6rem;
		background-color: transparent;
		border-radius: 8px;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2rem;
		height: 144px;
		resize: none; /* Prevent manual resizing */
		caret-color: var(--text-primary-color);

		@media screen and (min-width: $mobile-screen-max-width) {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
		}

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			font-weight: 400;
			color: var(--text-tertiary-color-v0);
		}
	}

	.textAreaInputError {
		border-color: var(--error-color);
	}

	.errorText {
		@include textEllipsis();
		margin-top: 0.8rem;
		height: 16px;
		line-height: 16px;
	}
}

.buttonsContainerWrapper {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-top: 1.6rem;
		padding-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}
}

.bookmarkActionContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.6rem;
	width: 100%;
	margin-bottom: 1.6rem;

	.checkbox {
		height: 1.6rem;
		width: 1.6rem;
		border: 1.5px solid var(--brand-primary-color);
		background-color: var(--background-primary-color);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		background-color: var(--brand-primary-color);
	}

	& > p {
		line-height: 1.6rem;
		width: 100%;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;

	.editBtn {
		height: 48px;
		width: 100%;
		border: 1px solid var(--border-secondary-color);
		border-radius: 8px;
		padding: unset;

		& > span:nth-child(2) {
			line-height: 22px;
		}
	}

	.sendBtn {
		height: 48px;
		width: 100%;
		padding: unset;
		border-radius: 8px;
		& > span:nth-child(2) {
			line-height: 22px;
		}
	}
}
