@import 'variables';
@import 'mixins';

@keyframes circle-pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
		box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
	}
	50% {
		-webkit-box-shadow: 0 0 0 6px rgba(77, 170, 87, 0);
		box-shadow: 0 0 0 6px rgba(77, 170, 87, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
		box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
	}
}

.discoveryCard {
	width: 320px;
	height: 299px;
	background: #ffffff73;
	box-shadow: 2px 2px 24px 0px #c7d8ee40;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;
	border-radius: 1.2rem;
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
	transition: all 0.5s ease-in-out;
	opacity: 1;
	transform: translateY(0);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: inherit;
		padding: 1px; /* Inner border thickness */
		background: conic-gradient(from 180deg at 50% 50%, #e8f0fa 0deg, #d2deec 360deg);
		-webkit-mask:
			linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		mask:
			linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
		z-index: -1;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 277px;
		width: 100%;
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	& > h2 {
		@include textEllipsis();
		padding-left: 2rem;
		padding-right: 2rem;
		margin-top: 2rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
			margin-top: 1.6rem;
		}
	}

	& > p {
		@include textEllipsis();
		padding-left: 2rem;
		padding-right: 2rem;
		margin-top: 0.8rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
			margin-top: 0.8rem;
		}
	}
}

.avatar {
	display: inline-block;
	align-self: center;
}

.updatesText {
	@include textEllipsis();
	margin-top: 0.8rem;
	margin-bottom: 2rem;
	position: relative;
	padding-left: 3.4rem;
	padding-right: 2rem;

	.dot {
		position: absolute;
		top: 50%;
		transform: translate(-1.4rem, -50%);
		right: auto;
		display: inline-block;
		width: 6px;
		height: 6px;
		background: #0f9e6f;
		animation: circle-pulse 2s infinite;
		border-radius: 9999px;
		-webkit-box-shadow: 0 0 0 0 #009966;
		box-shadow: 0 0 0 0 #009966;

		@media screen and (max-width: $mobile-screen-max-width) {
			width: 8px;
			height: 8px;
			transform: translate(-1.6rem, -50%);
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 0.8rem;
		margin-bottom: 1.6rem;
		padding-right: 2.4rem;
		padding-left: 4rem;
	}
}

.sendSuperDMButton {
	padding: unset;
	height: 44px;
	min-height: 44px;
	max-height: 44px;
	margin-left: 2rem;
	margin-right: 2rem;
	width: calc(100% - 4rem);
	border-radius: 6px;
	border: 1px solid #107fed;
	background: linear-gradient(180deg, #2e97fe 0%, #2181e0 100%);
	margin-top: auto;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 40px;
		min-height: 40px;
		max-height: 40px;
		margin-left: 2.4rem;
		margin-right: 2.4rem;
		width: calc(100% - 4.8rem);
	}
}
