@import 'variables';
@import 'mixins';

.chatsListContainer {
	flex: 1;
	overflow-y: auto;
}

.infoContainer {
	width: calc(100% - 4.8rem);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.2rem;
	background-color: #fef2e7;
	padding: 1.6rem;
	border-radius: 0.8rem;
	display: flex;
	column-gap: 0.6rem;
	align-items: flex-start;

	@media screen and (max-width: $mobile-screen-max-width) {
		width: calc(100% - 3.2rem);
		margin-top: 0.4rem;
	}
}

.chatWithSelectOption {
	display: grid;
	padding: 1.2rem 2.4rem;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'profilePic profileContent profileAction';
	column-gap: 1.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem 1.6rem;
	}

	&:focus-visible {
		outline: none;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
			cursor: pointer;
		}
	}

	.imageContainer {
		grid-area: profilePic;
	}

	.profileInfoContainer {
		grid-area: profileContent;
		overflow: hidden;

		& > h3 {
			@include textEllipsis();
		}

		& > p {
			@include textEllipsis();
			margin-top: 0.4rem;
		}
	}

	.actionContainer {
		grid-area: profileAction;
		align-self: center;

		.checkbox {
			height: 1.6rem;
			width: 1.6rem;
			border: 1.5px solid #c8d1da;
			background-color: var(--background-primary-color);
			border-radius: 3px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.checkboxActive {
			background-color: var(--brand-primary-color);
			border-color: var(--brand-primary-color);
		}
	}
}

.loadingContainer {
	& > p {
		text-align: center;
		padding: 2.4rem;
	}
}

.selectAllButton {
	padding: unset;
}

.headerBottomBorder {
	border-bottom: 1px solid var(--border-tertiary-color);
}
