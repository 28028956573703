.quickReplyBtnDesktop {
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 0.6rem;
	padding: unset;
	color: var(--brand-primary-color);

	.replyText {
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
	}

	svg path {
		stroke: var(--brand-primary-color);
		fill: var(--brand-primary-color);
	}

	&:hover,
	&:hover > span,
	&:hover > svg path {
		color: var(--text-primary-color);

		svg path {
			stroke: var(--text-primary-color);
			fill: var(--text-primary-color);
		}
	}
}

.popoverContent {
	box-shadow: 2px 2px 24px 0px #834f2912;
	border: 1px solid var(--border-tertiary-color);
	border-radius: 0.8rem;
	width: 256px;
	margin-top: 0.6rem;
	cursor: pointer;
	overflow: hidden;
	background-color: var(--background-primary-color);

	padding-top: 0.8rem;
	padding-bottom: 0.8rem;

	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline: none;
	}
}
