@import 'variables';
@import 'mixins';

.teamMemberSection {
	margin-top: 4rem;
	margin-bottom: 4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	& > p {
		margin-top: 0.6rem;
	}
}

.addMemberBtnWithIcon {
	margin-top: 1.6rem;
	border: 1px solid var(--border-secondary-color);
	border-radius: 0.8rem;
	gap: 0.6rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	height: 3.2rem;

	.plusIcon {
		padding: 0.2rem;
		path {
			stroke: var(--text-primary-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.listContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1.6rem;

	.loadingContainer {
		width: 100%;
		max-width: 140px;
		height: 2.4rem;
		overflow: hidden;
	}
}

.secondaryUserContainer {
	display: flex;
	align-items: center;
	padding: 1.6rem;
	background-color: var(--background-secondary-color);
	border-radius: 0.8rem;
	max-width: 360px;
	width: 100%;
	height: 50px;
	cursor: pointer;

	.name {
		@include textEllipsis();
		width: fit-content;
		max-width: 100%;
	}

	.categoryAccess {
		flex-shrink: 0;
		margin-right: 1.6rem;
	}

	.ellipse {
		height: 2px;
		width: 2px;
		min-width: 2px;
		min-height: 2px;
		background-color: #8b9095;
		margin-left: 0.4rem;
		margin-right: 0.4rem;
		border-radius: 9999px;
	}

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}

	& > span {
		display: block;
		margin-left: auto;
		svg path {
			fill: var(--text-tertiary-color);
		}
	}
}

.modalContainer {
	& > div {
		& > div {
			padding-top: 2.4rem;
			padding-bottom: 2.4rem;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 420px;
			height: 100%;

			& > div,
			& > p,
			& > h2 {
				padding-left: 2.4rem;
				padding-right: 2.4rem;
			}
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		& > div {
			max-height: 90%;
			& > div {
				padding-top: 2.4rem;
				padding-bottom: 1.6rem;
				width: 100%;

				& > div,
				& > p,
				& > h2 {
					padding-left: 1.6rem;
					padding-right: 1.6rem;
				}
			}
		}
	}
}

.mainHeader {
	display: flex;
	gap: 1.6rem;
	padding-bottom: 1.6rem;
	margin-bottom: 0.8rem;

	& > h2 {
		flex: 1;
		@include textEllipsis();
	}

	.btnWithIcon {
		padding: unset;

		.closeIcon {
			path {
				stroke: var(--text-tertiary-color);
			}
		}
	}
}

.mainHeaderBorder {
	border-bottom: 1px solid var(--border-tertiary-color);
}

.secondaryUserForms {
	flex: 1;
	overflow-y: auto;

	.inputField {
		&:not(:first-child) {
			margin-top: 2.4rem;
		}

		.input {
			width: 100%;
			outline: none;
			border: 1px solid var(--border-secondary-color);
			font-size: 1.4rem;
			font-weight: 400;
			line-height: 2rem;
			padding: 1.2rem;
			height: 44px;
			background-color: transparent;
			border-radius: 8px;
			margin-top: 0.8rem;

			&::placeholder {
				color: var(--text-tertiary-color);
				font-weight: 400;
			}

			&:disabled {
				color: var(--text-tertiary-color-v0);
			}
		}

		.errorInput {
			border-color: var(--error-color);
		}
	}

	.secondaryUserCheckboxSection {
		display: flex;
		flex-direction: column;
		margin-top: 2.4rem;

		.checkActionContainer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 0.6rem;
			width: 100%;

			& > p {
				@include textEllipsis();
			}

			.checkbox {
				height: 1.4rem;
				width: 1.4rem;
				min-height: 1.4rem;
				min-width: 1.4rem;
				border: 1.5px solid #c8d1da;
				background-color: var(--background-primary-color);
				border-radius: 4px;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.checkboxActive {
				background-color: var(--brand-primary-color);
				border: none;
			}

			@media screen and (min-width: $mobile-screen-max-width) {
				cursor: pointer;
			}
		}

		.limitedAccessContainer {
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			margin-top: 1.6rem;
			padding-left: 2.2rem;

			& > p {
				text-transform: uppercase;
			}

			.showAllCategoriesBtn {
				padding: unset;
				width: 100%;
				max-width: fit-content;
				overflow: hidden;
			}
		}
	}
}

.errorText {
	margin-top: 0.8rem;
	line-height: 16px;
}

.commonButtonStyles {
	height: 48px;
	width: 100%;
	border-radius: 8px;
	padding: unset;
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.8rem;
	padding-top: 1.6rem;
	gap: 1.6rem;

	& > button {
		@extend .commonButtonStyles;
	}

	.backBtn {
		border: 1px solid var(--border-secondary-color);
	}
}

.buttonContainerBorder {
	border-top: 1px solid var(--border-tertiary-color);
}

.deleteHeader {
	margin-bottom: 0.8rem;
}
