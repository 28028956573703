@import 'variables';

.labelBtn {
	all: unset;
	width: 2.4rem;
	height: 2.4rem;
	max-height: 2.4rem;
	min-height: 2.4rem;
	min-width: 2.4rem;
	max-width: 2.4rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}
}

.labelsPopoverContent {
	background-color: var(--background-primary-color);
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	box-shadow: 2px 2px 24px 0px #834f2912;
	border: 1px solid var(--border-tertiary-color);
	border-radius: 0.8rem;
	width: 256px;
	height: 100%;
	max-height: calc(100vh - 300px);
	margin-top: 0.6rem;

	display: flex;
	flex-direction: column;
	overflow: hidden;

	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline: none;
	}
}

.modalContainer {
	& > div:nth-child(1) {
		width: 420px;
		max-width: 100%;
		max-height: 90%;
		overflow: hidden;

		& > div:nth-child(1) {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.labelIcon {
	@media screen and (max-width: $mobile-screen-max-width) {
		path {
			fill: var(--background-primary-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			path {
				fill: var(--text-primary-color);
			}
		}
	}
}
