@import 'variables';
@import 'mixins';

.modalContainer {
	position: fixed;
	top: 0;
	left: 0;
	overflow: auto;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.5);
}

.modalContainerMobile {
	@media screen and (max-width: $mobile-screen-max-width) {
		overflow: hidden;
		align-items: end;
	}
}

.modalContentContainer {
	display: flex;
	flex-direction: column;
	max-width: 90%;
	max-height: 90%;
	padding: 2.4rem;
	background-color: var(--background-primary-color);
	border-radius: 1.2rem;
	overflow: hidden;
	transform: scale(0);
	animation: zoomIn 0.1s linear 0s 1 forwards;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
	}
}

@keyframes zoomIn {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

.modalContentContainerMobile {
	@media screen and (max-width: $mobile-screen-max-width) {
		overflow: hidden;
		transform: translateY(100%);
		animation: slideUp 0.1s linear 0s 1 forwards;
		flex: 1;
		max-width: 100%;
		max-height: 100%;
		border-bottom-left-radius: unset;
		border-bottom-right-radius: unset;
	}
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0%);
	}
}

.noPadding {
	padding: unset;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: unset;
	}
}

.modalHeaderSpacing {
	padding-top: 2.4rem;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
}

.headerSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	background-color: var(--primary-color-medium);
}

.content {
	max-width: 100%;
	overflow: auto;
}

.closeBtn {
	padding: unset;
}
