@import 'variables';
@import 'mixins';

.selectTrigger {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 0.6rem;
	padding: 0.8rem;
	gap: 0.6rem;
	background-color: var(--background-primary-color);
	height: fit-content;
	text-wrap: nowrap;
	width: fit-content;
	max-width: 100%;
	border: none;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}

	&:focus-visible {
		outline: none;
	}

	.selectIcon {
		height: 1.4rem;
		width: 1.4rem;
		transition: transform 0.2s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: inline-block;
		}
	}

	.selectIcon path {
		fill: var(--text-tertiary-color);
	}
}

.selectButtonActive {
	.selectIcon {
		transform: rotate(-180deg);
	}
}

.selectHeader {
	@include textEllipsis();
}

.selectContent {
	overflow: hidden;
	background-color: var(--background-primary-color);
	border-radius: $filter-border-radius;
	border: 1px solid var(--border-tertiary-color);
	height: fit-content;
	max-height: 500px;
	max-width: 300px;
	width: 100vw;
	margin-top: 0.2rem;
	overflow-y: auto;
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	margin-bottom: 0.4rem;
	padding-bottom: 1rem;
}

.selectItem {
	outline: none;
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}
}

.container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0.8rem 1.6rem;
	gap: 1.2rem;
}

.itemCommonStyles {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);
	border: 1.5px solid #c8d1da;
	min-width: 1.6rem;
	min-height: 1.6rem;
	max-width: 1.6rem;
	max-height: 1.6rem;
}

.itemActiveCommonStyles {
	border-color: var(--brand-primary-color);
	background-color: var(--brand-primary-color);
}

.radioItem {
	border-radius: 999px;
}

.radioItemActive {
	position: relative;

	&::before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		content: '';
		height: 0.6rem;
		width: 0.6rem;
		border-radius: 999px;
		background-color: var(--background-primary-color);
	}
}

.infoContainer {
	padding: 0.8rem 1.2rem;
	margin: 1.6rem 1.6rem 0.8rem 1.6rem;
	border-radius: 8px;
	background-color: #fef2e7;
	color: #da730b;
	border: 1px solid #facc9e;
}
