@import 'variables';
@import 'mixins';

.userInfo {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas: 'profileImage profileName' 'profileImage profileHeadline';
	text-align: start;
	row-gap: 0.3rem;

	width: 100%;
	max-width: $inbox-chat-user-info-max-width;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;
		padding-left: 0.8rem;
		row-gap: 0.4rem;
		max-width: unset;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #f9f9f9;
		}
	}

	.readStatusWithImage {
		grid-area: profileImage;
		margin-right: 1.2rem;
		display: flex;
		align-items: center;
		align-self: flex-start;

		.readStatus {
			height: 0.8rem;
			width: 0.8rem;
			border-radius: 999px;
			display: inline-block;
			align-self: center;
			margin-right: 0.8rem;
		}

		.readStatusActive {
			background-color: var(--brand-primary-color);
		}
	}

	.name {
		grid-area: profileName;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.4rem;
		@include textEllipsis();

		& > h2 {
			@include textEllipsis();
		}

		@media screen and (min-width: $mobile-screen-max-width) {
			align-items: flex-end;
		}
	}

	.headline {
		grid-area: profileHeadline;
		display: flex;
		flex-direction: column;
		row-gap: 0.3rem;
		@include textEllipsis();

		& > p {
			@include textEllipsis();
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			row-gap: 0.4rem;
		}

		.assignedLabelTextWithDot {
			display: inline-block;
			padding-right: 1.2rem;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				width: 0.2rem;
				height: 0.2rem;
				border-radius: 999px;
				background-color: var(--text-secondary-color);
				right: 0.5rem;
				top: 50%;
				transform: translate(0, -50%);
			}
		}

		.labelCountText {
			padding: 0.2rem 0.3rem;
			border-radius: 0.2rem;
			background-color: #f6f0ff;
			display: inline-block;
			margin-left: 0.4rem;

			@media screen and (max-width: $mobile-screen-max-width) {
				padding: 0.3rem;
			}
		}
	}
}

.userSelected {
	@media screen and (min-width: $mobile-screen-max-width) {
		background-color: #f9f9f9;
	}
}
