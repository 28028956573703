@import 'variables';

.profileContainer {
	width: 100%;
	max-width: 700px;
	background-color: var(--background-primary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	border-radius: 1.6rem;

	margin: auto;
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	position: relative;
	padding: 1.6rem 4.8rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		margin-top: unset;
		margin-bottom: unset;
		border-radius: unset;
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}
}

.loaderContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Intro {
	padding: 2.4rem 1.6rem 2.4rem 1.6rem;
	display: flex;
	gap: 1.6rem;

	.introContent {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;

		.textWithIcon {
			display: flex;
			align-items: center;
			gap: 0.8rem;

			.pendingIconBlack path {
				fill: #da730b;
			}
		}
	}

	.buttonContainer {
		display: flex;
		gap: 1.6em;

		.openAccountBtn {
			padding: unset;
			gap: 0.4rem;
			width: fit-content;
		}
		.openAccountIcon path {
			stroke: var(--brand-primary-color);
		}

		.resyncBtn {
			padding: unset;
			gap: 0.5rem;
			width: fit-content;
			position: relative;

			&::before {
				content: '';
				height: 0.4rem;
				width: 0.4rem;
				min-width: 0.4rem;
				min-height: 0.4rem;
				max-width: 0.4rem;
				max-height: 0.4rem;
				background-color: var(--background-tertiary-color);
				border-radius: 999px;
				position: absolute;
				left: -1rem;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: unset;
		padding-right: unset;
		padding-top: 1.6rem;
	}
}

.header {
	display: flex;
	align-items: center;
	gap: 1.2rem;

	& > span {
		display: inline-block;
		flex: 1;
		height: 1px;
		background-color: var(--border-tertiary-color);
	}
}

.experienceSection {
	margin-top: 1.6rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: unset;
		padding-right: unset;
	}
}

.experienceInfo {
	display: flex;
	gap: 1.6rem;

	.experienceLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.experienceContent {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
	}

	.moreExperienceContent {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
	}
}

.companyName {
	& > span::before {
		content: '';
		height: 3px;
		width: 3px;
		background-color: var(--text-primary-color);
		border-radius: 999px;
		margin-left: 0.6rem;
		margin-right: 0.6rem;
		margin-bottom: 0.2rem;
		display: inline-block;
	}
}

.yearsMonthText {
	@extend .companyName;
	& > span::before {
		background-color: var(--text-tertiary-color);
	}
}

.educationSection {
	margin-top: 4rem;
	margin-bottom: 1rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: unset;
		padding-right: unset;
	}
}

.educationInfo {
	display: flex;
	gap: 1.2rem;

	.educationLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.educationContent {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
	}
}

.modalContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2.4rem;
	gap: 2.4rem;
	overflow-y: auto;
	width: 420px;
	max-width: 100%;
	margin: 0 auto;
	background-color: var(--background-primary-color);
	min-height: 180px;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		width: 100%;
	}
}

.modalContentWithCancel {
	@media screen and (min-width: $mobile-screen-max-width) {
		min-height: 196px;
	}
}

.modalTextContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.modalHeader {
	font-size: 2rem;
	line-height: 2.4rem;
}

.modalText {
	font-size: 1.6rem;
	line-height: 2.2rem;
	margin-top: 0.8rem;
}

.modalButton {
	height: 4rem;
	width: 100%;
	border-radius: 0.4rem;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.centeredText {
	text-align: center;
	margin-top: 2.4rem;
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.resyncLoading {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;
}

.modalCancelBtn {
	position: absolute;
	top: 2.5rem;
	right: 2.4rem;
	padding: unset;
}

.modalTextMargin {
	margin-top: 1.6rem;
}
