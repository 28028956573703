@import 'variables';
@import 'mixins';

.commonTextStyles {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 2.4rem;
	color: var(--text-primary-color);
	@include fontFamily();
	word-break: break-word;
}

.bold {
	font-weight: 700;
}

.semiBold {
	font-weight: 600;
}

.light {
	font-weight: 400;
}

.ultraLight {
	font-weight: 300;
}

.brandPrimaryColor {
	color: var(--text-blue-primary-color);
}

.brandSecondaryColor {
	color: var(--text-blue-secondary-color);
}

.brandTertiaryColor {
	color: var(--text-blue-tertiary-color);
}
.secondary {
	color: var(--text-secondary-color);
}

.tertiary {
	color: var(--text-tertiary-color);
}

.tertiaryV0 {
	color: var(--text-tertiary-color-v0);
}

.disabled {
	color: var(--text-disabled-color);
}

.extraLarge {
	font-size: 4rem;
	line-height: 4.8rem;
}

.large {
	font-size: 3.2rem;
	line-height: 4.2rem;
}

.medium2M {
	font-size: 2.4rem;
	line-height: 3.2rem;
}

.extraMedium {
	font-size: 2.8rem;
	line-height: 3.6rem;
}

.medium {
	font-size: 2rem;
	line-height: 2.4rem;
}

.small {
	font-size: 1.4rem;
	line-height: 1.8rem;
}

.tiny {
	font-size: 1.2rem;
	line-height: 1.4rem;
}

.extraTiny {
	font-size: 1rem;
	line-height: 1.4rem;
}

.white {
	color: var(--background-primary-color);
}

.error {
	color: var(--error-color);
}

.success {
	color: var(--success-color);
}
