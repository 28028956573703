@import 'mixins';
@import 'variables';

.chatsToastRoot {
	position: fixed;
	top: 1.2rem;
	width: 100%;
	z-index: 2147483647;

	@media screen and (min-width: $mobile-screen-max-width) {
		bottom: 2rem;
		top: unset;
	}
}

.chatActionToastContainer {
	display: flex;
	align-items: center;
	padding: 1.2rem;
	max-width: 100%;
	width: 264px;
	border-radius: 4px;
	background-color: #292929;
	box-shadow: 2px 2px 16px 0px #00000014;
	margin: auto;
	height: 40px;
	transform: translateY(-100%);

	animation: slideDown 150ms ease-in-out 1 forwards;

	@media screen and (min-width: $mobile-screen-max-width) {
		transform: translateY(100%);
		animation: slideUp 150ms ease-in-out 1 forwards;
	}

	.statusText {
		@include textEllipsis();
		line-height: 20px;
		margin-right: 0.4rem;
	}

	.actionBtn {
		padding: unset;
		width: fit-content;
		align-items: center;
		justify-content: center;

		& > span {
			line-height: 16px;
			display: inline-block;
			margin-top: 0.1rem;
			text-decoration: underline;
		}
	}

	.closeBtn {
		padding: unset;
		margin-left: auto;

		.closeIcon {
			& > path {
				fill: #5f5f5f;
			}
		}
	}

	@keyframes slideDown {
		0% {
			transform: translateY(-100%);
		}
		100% {
			transform: translateY(0%);
		}
	}

	@keyframes slideUp {
		0% {
			transform: translateY(100%);
		}
		100% {
			transform: translateY(0%);
		}
	}
}
