/* YourStyles.module.css */
@keyframes slideUp {
	0% {
		transform: translateY(50%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.slideContainer {
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.slideUp {
	animation: slideUp 0.5s forwards;
	width: 100%; /* Ensures the child takes the full width of the container */
}
