@import 'mixins';
@import 'variables';

.cannedResponsesListContainer {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $mobile-screen-max-width) {
		& > div:hover {
			cursor: pointer;
			background-color: var(--background-secondary-color);
		}
	}
}

.focused {
	outline: none;
	background-color: var(--background-secondary-color);
}

.cannedResponse {
	padding: 1rem 2rem;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 1.6rem;
	align-items: center;

	& > p {
		@include textEllipsis();
	}
}

.manageCannedResponsesBtn {
	padding: 1rem 2rem;
	display: flex;
	gap: 0.8rem;
	align-items: center;

	& > h2 {
		@include textEllipsis();
	}
}
