@import 'variables';

.mainLayoutContainer {
	display: flex;
	flex-direction: column;
	margin: auto;
	height: 100%;
	width: 100%;
}

.mainHeaderContainer {
	background: linear-gradient(180deg, #004ab9 0%, #0066ff 85.36%);
	padding-left: $layout-left-margin;
	padding-right: $layout-right-margin;
	height: 118px;

	@media (max-width: $desktop-screen-max-width) {
		background: linear-gradient(180deg, #004ab9 100%, #0066ff 100%);
		padding-left: unset;
		padding-right: unset;
	}
}

.proUserMainHeaderHeight {
	height: 170px;
}

.mainContainer {
	flex: 1;
	overflow: hidden;
	padding-left: $layout-left-margin;
	padding-right: $layout-right-margin;

	margin-top: -54px;

	@media (max-width: $desktop-screen-max-width) {
		padding-left: unset;
		padding-right: unset;
	}
}

.proUserMainContainerHeight {
	margin-top: -106px;
}
