@import 'mixins';
@import 'variables';

.profileCard {
	width: 100%;
	max-width: 400px;
	padding: 1.6rem;
	border-radius: 8px;

	display: grid;
	grid-template-rows: auto auto auto;
	grid-template-columns: auto 1fr;
	grid-template-areas: 'profileImage profileName' 'profileImage profileDesignation' 'profileImage location';
	column-gap: 1.6rem;
	text-align: start;
	align-items: center;
	row-gap: 0.4rem;

	background-color: var(--background-primary-color);
	border: 1px solid var(--border-secondary-color);

	.image {
		grid-area: profileImage;
		overflow: hidden;

		svg,
		img {
			border: 1px solid var(--border-tertiary-color);
			border-radius: 9999px;
		}
	}

	.name {
		grid-area: profileName;
		@include textEllipsis();
	}

	.designation {
		grid-area: profileDesignation;
		@include textEllipsis();
	}

	.location {
		@include textEllipsis();
		grid-area: location;
	}
}
