@import 'variables';

.archivedInfoContainer {
	& > p:nth-child(1) {
		font-size: 13px;
		line-height: 16px;
		margin: 1.6rem;
		text-align: center;

		@media screen and (max-width: $mobile-screen-max-width) {
			font-size: 12px;
		}
	}
}

.archivedInfoListContainer {
	margin: 1.6rem 1.6rem 0 1.6rem;
	padding: 1.6rem;
	background-color: #fffaf6;
	border-radius: 12px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	grid-template-areas: 'title image' 'list image';
	row-gap: 1.2rem;
	column-gap: 3.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		grid-template-areas: 'title' 'list';
		row-gap: 0.8rem;
	}

	& > h3 {
		grid-area: title;
		font-size: 16px;
		line-height: 22px;
		color: #7e4502;
	}

	.archivedInfoList {
		grid-area: list;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		column-gap: 2.4rem;
		row-gap: 0.6rem;

		.archivedInfoItem {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0.6rem;

			.tickIcon {
				display: block;
				margin-top: 2px;
				path {
					fill: #7e4502;
				}
			}

			& > p {
				line-height: 20px;
				color: #7e4502;
			}
		}
	}

	.archiveIconBox {
		grid-area: image;
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 5.2rem;
		width: 5.2rem;
		background-color: #fff1e6;
		border-radius: 99999px;
		@media screen and (max-width: $mobile-screen-max-width) {
			display: none;
		}
	}
}
