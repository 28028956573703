@import 'variables';
@import 'mixins';

$loginStepContainerHeight: 204px;
$loginStepContainerHeightMobile: 198px;

.loginStepContainer {
	height: 0px;
	overflow: hidden;
	position: relative;

	& > h2 {
		@include textEllipsis();
	}

	input {
		caret-color: var(--text-primary-color);
	}
}

.loginStepContainerLoadingState {
	input {
		caret-color: var(--background-primary-color);
	}
}

.loginContainerHeight {
	height: $loginStepContainerHeight;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: $loginStepContainerHeightMobile;
	}
}

.animateLoginStepContainer {
	animation: slideUp 0.3s ease-in-out 0.4s forwards;

	@media screen and (max-width: $mobile-screen-max-width) {
		animation: slideUpMobile 0.3s ease-in-out 0.4s forwards;
	}
}

@keyframes slideUp {
	from {
		height: 0px;
	}
	to {
		height: $loginStepContainerHeight;
	}
}

@keyframes slideUpMobile {
	from {
		height: 0px;
	}
	to {
		height: $loginStepContainerHeightMobile;
	}
}

.loadingContainer {
	position: absolute;
	height: $loginStepContainerHeight;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		height: $loginStepContainerHeightMobile;
	}
}

.subText {
	@include textEllipsis();
	margin-top: 0.6rem;
	margin-bottom: 2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 0.4rem;
		margin-bottom: 1.6rem;
	}
}

.mailTextContainer {
	display: flex;
	align-items: center;
	gap: 0.6rem;
	margin-top: 0.6rem;
	margin-bottom: 2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 0.4rem;
		margin-bottom: 1.6rem;
	}

	& > p {
		margin: unset;
	}

	.backBtn {
		padding: unset;
		& > span {
			text-decoration: underline;
		}
	}
}

.googleBtnContainer {
	.googleBtn {
		gap: 0.6rem;
		height: 4.2rem;
		width: 100%;
		justify-content: center;
		border-radius: 0.6rem;
	}

	& > span {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}
}

.inputWithBtn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.2rem;
	padding: 0.6rem;
	border: 1px solid var(--border-secondary-color);
	border-radius: 0.6rem;
	height: 4.2rem;
}

.inputWithBtnActiveState {
	border-color: var(--text-primary-color);
}

.input {
	width: 100%;
	height: 34px;
	outline: none;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 2rem;
	background-color: transparent;
	border: none;
	padding-left: 0.6rem;

	&::placeholder {
		color: var(--text-tertiary-color);
		font-weight: 300;
	}

	&:focus-visible {
		outline: none;
	}
}

.otpInputsContainer {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;

	@media screen and (min-width: $mobile-screen-max-width) {
		justify-content: start;
		gap: 1.2rem;
	}
}

.otpInputWithDot {
	.otpInput {
		border: 1px solid var(--border-secondary-color);
		border-radius: 0.6rem;
		height: 4.8rem;
		width: 4.8rem;
		text-align: center;
		font-size: 2.4rem;
		line-height: 3.2rem;
		font-weight: 500;
		margin-top: 0.8rem;

		&:focus {
			border-color: var(--text-primary-color);
		}

		&:focus-visible {
			outline: none;
		}
	}

	.otpInputActiveState {
		border-color: var(--text-primary-color);
	}
}

.otpInputInitialState {
	position: relative;
	&::after {
		position: absolute;
		content: '';
		height: 4px;
		width: 4px;
		background-color: var(--text-tertiary-color);
		border-radius: 9999px;
		transform: translate(-50%, -50%);
		top: calc(50% + 0.4rem);
		left: 50%;
	}
}

.otpInputErrorState {
	border-color: var(--error-color);
}

.errorText {
	@include textEllipsis();
	margin-top: 1.2rem;
	height: 16px;
	text-align: start;
}

.continueBtn {
	height: 3rem;
	width: 3rem;
	border-radius: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;

	& > span {
		display: inline-block;
	}

	& > div {
		margin: unset;
	}

	.arrowIcon {
		transform: rotate(180deg);
	}
}
