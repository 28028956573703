@import 'variables';
@import 'mixins';

.profileSection {
	padding: 1.6rem;
	border-radius: 1.6rem;
	border: 1px solid #eff0f2;
	box-shadow: 0px 6px 20px 0px #2b333b0d;

	* {
		letter-spacing: -0.15px;
	}
}

.Intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	.nameWithIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.8rem;
		margin-top: 0.8rem;
		width: 100%;

		& > h2 {
			width: fit-content;
			max-width: 100%;
			@include textEllipsis();
			line-height: 22px;
			font-size: 17px;
		}
	}

	.pendingIcon path {
		fill: #da730b;
	}

	& > p {
		margin-top: 0.6rem;
	}

	.headline {
		width: 100%;
		margin-top: 0.4rem;
		line-height: 16px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		font-size: 13px;
	}

	.location {
		width: 100%;
		line-height: 16px;
		@include textEllipsis();
		font-size: 13px;
	}
}

.experienceSection {
	margin-top: 1.2rem;
	margin-bottom: 1.2rem;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;

	& > h2 {
		line-height: 16px;
		font-size: 11px;
	}

	& > span {
		margin-left: 4.8rem;
		line-height: 16px;
		margin-top: -4px;
		font-size: 13px;
	}
}

.experienceInfo {
	display: flex;
	gap: 1.2rem;

	.experienceLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.experienceContent {
		overflow: hidden;

		& > h3 {
			@include textEllipsis();
			line-height: 16px;
			font-size: 13px;
		}

		& > p {
			margin-top: 0.4rem;
			line-height: 16px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-size: 13px;

			& > span {
				font-size: 13px;
			}
		}
	}
}

.yearsMonthText {
	& > span::before {
		content: '';
		height: 2px;
		width: 2px;
		background-color: var(--text-primary-color);
		border-radius: 999px;
		margin-left: 0.4rem;
		margin-right: 0.4rem;
		margin-bottom: 0.2rem;
		display: inline-block;
		background-color: var(--text-secondary-color);
	}
}

.educationSection {
	margin-top: 1.2rem;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;

	& > h2 {
		line-height: 16px;
		font-size: 11px;
	}

	& > span {
		margin-left: 4.8rem;
		line-height: 16px;
		margin-top: -4px;
		font-size: 13px;
	}
}

.educationInfo {
	display: flex;
	gap: 1.2rem;

	.educationLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.educationContent {
		overflow: hidden;

		& > h3 {
			@include textEllipsis();
			line-height: 16px;
			font-size: 13px;
		}

		& > p {
			margin-top: 0.4rem;
			line-height: 16px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-size: 13px;
		}
	}
}

.viewProfileBtn {
	width: 100%;
	margin-top: 1.6rem;
	padding: unset;

	& > span {
		line-height: 20px;
		line-height: 15px;
	}
}
