@import 'variables';

.offlineContainer {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--background-primary-color);

	& > h1 {
		line-height: 2.4rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			line-height: 2.8rem;
		}
	}

	& > p {
		line-height: 2rem;
		margin-top: 0.8rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			line-height: 2.2rem;
		}
	}

	& > button {
		margin-top: 2.4rem;
		height: 4.8rem;
		width: 104px;
		border-radius: 8px;
		& > span {
			line-height: 2.2rem;
		}
	}
}

.offlineBtnBlinkEffect {
	@media screen and (max-width: $mobile-screen-max-width) {
		animation: blinkBackground 150ms ease-in-out;

		@keyframes blinkBackground {
			0% {
				background-color: var(--brand-primary-color);
			}
			100% {
				background-color: var(--brand-primary-color-active);
			}
		}
	}
}
