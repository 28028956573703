@import 'variables';
@import 'mixins';

.avatar {
	height: 100%;
	width: 100%;
	border-radius: 999px;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
}
