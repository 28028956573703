@import 'variables';

.singleClickSelectTrigger {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $filter-border-radius;
	padding: 0.8rem 1.2rem;
	gap: 0.6rem;
	border: 1px solid var(--filter-border-color);
	background-color: var(--filter-background-color);
	height: fit-content;
	text-wrap: nowrap;
	height: 34px;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}

	&:focus-visible {
		outline: none;
	}

	.singleClickSelectCloseIcon {
		display: inline-block;
	}

	.singleClickSelectCloseIcon path {
		fill: var(--filter-active-icon-color);
	}

	& > span:nth-child(1) {
		white-space: nowrap;
		line-height: 1.8rem;
		color: var(--filter-text-color);
		font-weight: 500;
		font-size: 1.2rem;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		height: 36px;

		&:hover {
			cursor: pointer;
		}

		& > span:nth-child(1) {
			line-height: 2rem;
			font-weight: 400;
			font-size: 1.4rem;
		}
	}
}

.singleClickSelectTriggerActive {
	background-color: var(--filter-active-background-color);
	border-color: var(--filter-active-border-color);
	padding-right: 1rem;

	& > span:nth-child(1) {
		color: var(--filter-active-text-color);
		font-weight: 600;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-right: 0.8rem;
		&:hover {
			background-color: var(--filter-active-background-color);
		}
	}
}

.singleClickSelectButtonActive {
	border: 1px solid var(--filter-active-border-color);
}

.switchContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	height: 36px;

	& > span {
		display: block;
		width: max-content;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		flex-direction: column;
		gap: 0.6rem;
		height: 34px;
	}
}

.switchRoot {
	width: 36px;
	height: 20px;
	border-radius: 9999px;
	position: relative;
	outline: none;
	border: none;
	background-color: var(--border-secondary-color);

	&:focus,
	&:focus-visible {
		outline: none;
		border: none;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 34px;
		height: 18px;
	}
}

.switchRoot[data-state='checked'] {
	background-color: var(--text-primary-color);
}

.switchThumb {
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 9999px;
	transition: transform 100ms;
	transform: translateX(2px);
	will-change: transform;
	outline: none;
	border: none;

	box-shadow: 0px 1.88px 3.75px 0px #3c5e7c52;
	background-color: var(--background-primary-color);

	&:focus,
	&:focus-visible {
		outline: none;
		border: none;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 14px;
		height: 14px;
	}
}

.switchThumb[data-state='checked'] {
	transform: translateX(18px);

	@media screen and (max-width: $mobile-screen-max-width) {
		transform: translateX(18px);
	}
}
