@import 'variables';

.preferencesSection {
	margin-top: 4rem;
	margin-bottom: 4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	& > p {
		margin-top: 0.6rem;
	}
}

.checkActionContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.6rem;
	width: 100%;
	margin-top: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	.checkbox {
		height: 1.6rem;
		width: 1.6rem;
		border: 1.5px solid var(--brand-primary-color);
		background-color: var(--background-primary-color);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		background-color: var(--brand-primary-color);
	}

	& > p {
		line-height: 2rem;
		width: 100%;
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
