@import 'variables';
@import 'mixins';

.onBoardingContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	overflow: auto;
}

.onBoardingContainerPaddingMobile {
	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-bottom: 1.6rem;
	}
}

.accountUnderReviewContainer {
	padding: 6.4rem 12rem;
	@include onboardingCardStyles();
	@media screen and (max-width: $mobile-screen-max-width) {
		padding: unset;
		box-shadow: unset;
	}
}

.icon path {
	stroke: #b3bfcc;
}

.underReviewText {
	margin-top: 4rem;
	margin-bottom: 0.8rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 1.6rem;
	}
}
