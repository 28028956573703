// declare variables for common css properties

// typography
$global-font-size: 62.5%;
$global-mobile-font-size: 56%;
/* makes 1rem 10px */

$line-height: 1.25;

$box-shadow: 2px 2px 24px 0px #834f2912;

$toast-box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);

$border-radius: 0.4rem;
$filter-border-radius: 0.8rem;
$inbox-main-layout-border-radius: 1.6rem;

$app-header-height: 64px;
$onboarding-layout-header-desktop: 80px;
$onboarding-layout-header-mobile: 56px;

$scroll-card-view-max-width: 820px;

// spacing

// responsiveness
$desktop-screen-max-width: 1200px;
$tab-screen-max-width: 1024px;
$mobile-screen-max-width: 650px;

$layout-right-margin: 120px;
$layout-left-margin: 120px;

$layout-max-width: calc(100% - 240px);

$inbox-chat-user-info-max-width: 320px;
