@import 'variables';
@import 'mixins';

.chatsContent {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	overflow: auto;
	overflow-anchor: none;
}

.loadingContainer {
	height: 100%;
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--background-primary-color);
}

.container {
	overflow: auto;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-anchor: none;
	padding: 1.6rem 1.6rem 0 1.6rem;
}

.addBorderTop {
	border-top: 1px solid var(--border-tertiary-color);
}

.selectedCategoryWithLinkedIn {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > article:nth-child(1) {
		width: calc(100vw - 5.6rem);
		margin-bottom: 2.4rem;
	}
}

.selectedCategoryContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	margin-bottom: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 1.2rem;
	}
}

.categoryLabel {
	padding: 0.8rem 1.6rem;
	background-color: var(--background-blue-secondary-color);
	color: #488cd1;
	border-radius: 1.6rem;
	@include textEllipsis();
}

.reportContainer {
	display: flex;
	gap: 0.4rem;
	justify-content: center;
	align-items: center;

	.reportBtn {
		padding: unset;

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover > span {
				color: var(--text-primary-color);
			}
		}
	}
}

.chatMainContainer {
	position: relative;
	width: 100%;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			.reactionBtn {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.moreActions {
				visibility: visible;
			}
		}
	}
}

.chatContainer {
	width: 90%;
	max-width: fit-content;
	position: relative;
	margin-bottom: 0.6rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& > p {
		width: fit-content;
	}
}

.chatContainerColored {
	margin-left: auto;
	align-items: flex-end;
}

.chatContainerMarginBottom {
	margin-bottom: 1.6rem;
}

.textWithReaction {
	position: relative;
}

.reactionBtn {
	position: absolute;
	background-color: #f9f9f9;
	border-radius: 999px;
	padding: unset;
	top: 50%;
	left: 100%;
	transform: translate(0, -50%);
	margin-left: 0.6rem;
	padding: 0.6rem;
	display: none;

	@media screen and (max-width: $mobile-screen-max-width) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.selectedReactionBtn {
	@extend .reactionBtn;
	display: block;
	transform: unset;
	transform: scale(0);
	border-radius: 999px;
	top: calc(100% - 0.8rem);
	left: 0;
	padding: 0.4rem 0.6rem 0.6rem 0.6rem;
	border: 1px solid var(--background-primary-color);
	animation: zoomIn 150ms ease-in-out 0ms 1 forwards;
}

.selectReactionSameUser {
	left: calc(100% - 4.4rem);
}

@keyframes zoomIn {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
}

.content {
	border-radius: 0.8rem;
	background-color: #f9f9f9;
	font-size: 1.4rem;
	line-height: 2rem;
	padding: 1.2rem;
	white-space: pre-wrap;

	& > a {
		color: inherit;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		font-size: 15px;
	}
}

.contentColored {
	background-color: var(--brand-primary-color);
}

.pdfAttachmentContainer {
	@extend .content;
	display: flex;
	align-items: center;
	gap: 0.8rem;
	margin-top: 0.6rem;
	max-width: 200px;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	.pdfFileName {
		@include textEllipsis();
		line-height: 2rem;
		@media screen and (max-width: $mobile-screen-max-width) {
			font-size: 15px;
		}
	}

	.downloadIconBlack path {
		stroke: var(--text-primary-color);
	}
}

.timeIconContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.25rem;
	margin-top: 0.4rem;
	font-size: 1.1rem;

	& > span {
		font-size: 1.1rem;
		display: inline-block;
		margin-left: 1.2ch;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			height: 0.15rem;
			width: 0.15rem;
			transform: translateY(-50%);
			background-color: var(--text-tertiary-color);
			border-radius: 9999px;
			left: -0.9ch;
		}
	}
}

.failedTextBtn {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 0.4rem;
	font-size: 1.1rem;
	padding: unset;
	width: 100%;
	gap: 0.2rem;
	color: var(--error-color);
	height: 1.4rem;

	& > p {
		font-size: 1.1rem;
	}
}

.senderInfo {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 0.8rem;
	margin-bottom: 0.4rem;
	overflow-x: hidden;
	width: 100%;

	& > h2 {
		@include textEllipsis();
		width: 100%;
		margin-top: 1.2rem;
		text-align: center;
	}

	.textWithBlinkContainer {
		margin-left: auto;
		margin-right: auto;
		margin-top: 0.4rem;
		width: auto;
		max-width: 100%;
		overflow-x: hidden;

		.updatesText {
			@include textEllipsis();
			width: 100%;
			font-size: 1.3rem;
			line-height: 1.8rem;
			color: #009966;

			@media screen and (max-width: $mobile-screen-max-width) {
				font-size: 1.3rem;
			}
		}
	}
}

.preferenceContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;
	margin-bottom: 1.6rem;
}

.senderPreferenceCard {
	background-color: #f9f9f9;
	padding: 1.6rem;
	border-radius: 0.8rem;
	white-space: pre-wrap;
	width: 100%;
	line-height: 2rem;

	& > a {
		color: inherit;
		text-decoration: underline;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem;
	}
}

.reportModalContentContainer {
	width: 100%;
	max-width: 420px;

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}

	& > p {
		margin-top: 1.6rem;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	gap: 1.6rem;
}

.modalCancelBtn {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	border: 1px solid var(--border-secondary-color);
	border-radius: 0.8rem;
	height: 44px;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.modalReportBtn {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	background-color: var(--error-color);
	border-radius: 0.8rem;
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--error-hover-color);
		}
	}
}

.undoReactionAlertModalContentContainer {
	width: 100%;
	max-width: 420px;

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}

	& > p {
		margin-top: 0.8rem;
	}

	& button {
		min-width: 130px;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}
}

.modalUndoBtn {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	border-radius: 0.8rem;
	height: 44px;
}

.textContentMargin {
	margin-bottom: 2rem;
}

.deleteModalContentContainer {
	@extend .reportModalContentContainer;

	& > p {
		margin-top: 0.8rem;
	}
}

.modalDeleteBtn {
	@extend .modalReportBtn;
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.moreActions {
	position: absolute;
	background-color: var(--background-secondary-color);
	border-radius: 999px;
	top: 50%;
	right: 100%;
	transform: translate(0, -50%);
	margin-right: 0.6rem;
	padding: 0.6rem;
	visibility: hidden;
	border: none;
	outline: unset;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	& > span {
		transform: rotate(90deg);
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		visibility: visible;
	}
}

.moreActions[data-state='open'] {
	visibility: visible;
	@media screen and (max-width: $mobile-screen-max-width) {
		visibility: visible;
	}
}

.moreActions[data-state='closed'] {
	visibility: hidden;
	@media screen and (max-width: $mobile-screen-max-width) {
		visibility: visible;
	}
}

.moreActionsContent {
	overflow: hidden;
	background-color: var(--background-primary-color);
	border: 1px solid var(--border-tertiary-color);
	border-radius: 8px;
	height: fit-content;
	width: fit-content;
	margin-top: 0.6rem;
	overflow-y: auto;
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;

	& > button {
		width: 100%;
		padding: 0.8rem 1.6rem;
		justify-content: flex-start;
		gap: 0.8rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: var(--background-secondary-color);
			}
		}
	}
}

.loadingContainer {
	& > p {
		text-align: center;
		padding: 2.4rem;
	}
}

.emailSignifierMarginBottom {
	margin-bottom: 1.6rem;
}

.emailSignifierMarginBottomRecent {
	margin-bottom: 2rem;
}

.bookmarkNudge {
	margin-top: 1.6rem;
	margin-left: auto;
}

.foundViaContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;
	margin-bottom: 0.8rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-bottom: unset;
	}

	& > span {
		display: inline-block;
	}
}
