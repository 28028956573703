@import 'variables';

.allFiltersMainContainer {
	display: flex;
	align-items: center;
	overflow: hidden;
	background-color: var(--background-primary-color);
	z-index: 1;
	position: sticky;
	top: 0;
}

.allFiltersContainer {
	display: flex;
	height: fit-content;
	padding: 1.6rem;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.overviewFilter {
	border: 1px solid var(--filter-border-color);
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	border-radius: $filter-border-radius;
	height: 34px;
	margin-right: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--filter-background-color);

	& > span {
		display: inline-block;
		white-space: nowrap;
		font-size: 1.2rem;
		line-height: 1.8rem;
		font-weight: 500;
		color: var(--filter-text-color);
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		height: 36px;

		&:hover {
			cursor: pointer;
		}

		& > span {
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 400;
		}
	}
}

.filterContainer:not(:last-child) {
	margin-right: 0.8rem;
}

.filtersContainerWithChevron {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.chevronLeft {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 16px;
	opacity: 0;
	transition: opacity 0.1s linear;
	z-index: 1;

	background-color: var(--background-primary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}

	& > button {
		border: 1px solid var(--background-tertiary-color);
		background-color: var(--background-primary-color);
		border-radius: 999px;
		width: 2.4rem;
		height: 2.4rem;
		margin-left: calc(100% + 0.6rem);
		transform: rotate(180deg);
		box-shadow: 0px 4px 12px 0px #2b333b1a;

		.icon {
			& > path {
				background: #7b8c9e;
			}
		}
	}
}

.chevronRight {
	@extend .chevronLeft;
	left: unset;
	right: 0;
	transform: unset;

	& > button {
		transform: unset;
		margin-left: unset;
		margin-right: calc(100% + 0.6rem);
	}
}

.showChevron {
	visibility: visible;
	opacity: 1;
}

.actionsContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 0.8rem;

	.divider {
		width: 1px;
		height: 26px;
		margin-left: 0.4rem;
		margin-right: 0.4rem;
		margin-top: auto;
		margin-bottom: auto;
		background-color: #dfeeff;
	}

	.clearFiltersBtn {
		height: 36px;
		padding: unset;
		padding-left: 0.8rem;
		padding-right: 0.8rem;

		& > span {
			font-size: 1.4rem;
			line-height: 2rem;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 34px;
			padding-right: unset;

			& > span {
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-right: unset;
	}
}

.clearFiltersBtnMobile {
	height: 34px;
	width: 34px;
	flex-shrink: 0;
	border: 1px solid var(--filter-active-border-color);
	background-color: var(--filter-active-background-color);
	margin-right: 0.8rem;
	border-radius: 0.8rem;
	padding: unset;

	& > span:nth-child(1) {
		svg path {
			stroke: #2b333b;
		}
	}
}

.unreadWithBorder:not(:last-child) {
	margin-right: unset;
	padding-right: 2.4rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		height: 28px;
		width: 1px;
		background-color: var(--filter-border-color);
		right: 13px;
		top: 50%;
		transform: translateY(-50%);
	}
}
