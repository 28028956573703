@import 'variables';

.ToastViewport {
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: fit-content;
	max-width: 100vw;
	margin: 0;
	list-style: none;
	z-index: 2147483647;
	outline: none;
	padding: 2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
		width: 100%;
	}
}

.ToastRoot {
	background-color: var(--background-primary-color);
	border-radius: 0.8rem;
	box-shadow: $toast-box-shadow;
	overflow: hidden;
}
.ToastRoot[data-state='open'] {
	animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-swipe='move'] {
	transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe='cancel'] {
	transform: translateX(0);
	transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
	animation: swipeOut 100ms ease-out;
}

@keyframes slideIn {
	from {
		transform: translateX(calc(100% + 2rem));
	}
	to {
		transform: translateX(0);
	}
}

@keyframes swipeOut {
	from {
		transform: translateX(var(--radix-toast-swipe-end-x));
	}
	to {
		transform: translateX(calc(100% + 2rem));
	}
}

.toastContainer {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas: 'icon header cancelIcon';
	column-gap: 0.8rem;
	row-gap: 0.4rem;
	text-align: start;
	padding: 1.2rem;
}

.toastInfoContainer {
	background-color: var(--text-primary-color);
	grid-template-rows: auto;
	grid-template-areas: 'icon header cancelIcon';
}

.toastInfoWithContentContainer {
	background-color: var(--text-primary-color);
	grid-template-rows: auto auto;
	grid-template-areas: 'icon header cancelIcon' 'icon content cancelIcon';
}

.toastErrorContainer {
	background-color: var(--error-color);
	grid-template-rows: auto;
	grid-template-areas: 'icon header cancelIcon';
}

.toastErrorWithContentContainer {
	background-color: var(--error-color);
	grid-template-rows: auto auto;
	grid-template-areas: 'icon header cancelIcon' 'icon content cancelIcon';
}

.toastSuccessContainer {
	background-color: var(--success-color);
	grid-template-rows: auto;
	grid-template-areas: 'icon header cancelIcon';
}

.toastSuccessWithContentContainer {
	background-color: var(--success-color);
	grid-template-rows: auto auto;
	grid-template-areas: 'icon header cancelIcon' 'icon content cancelIcon';
}

.toastIcon {
	grid-area: icon;
	display: flex;
	justify-self: flex-start;
}

.toastHeader {
	grid-area: header;
}

.toastContent {
	grid-area: content;
	opacity: 0.75;
}

.toastCancelIcon {
	grid-area: cancelIcon;
	display: flex;
	justify-self: flex-start;
	margin-left: 0.8rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}
}

.cancelIcon path {
	stroke: var(--text-primary-color);
}

.cancelIconInfo path {
	stroke: #8e9092;
}

.cancelIconError path {
	stroke: #f8b1b1;
}

.cancelIconSuccess path {
	stroke: #90ecbd;
}
