@import 'variables';
@import 'mixins';

.headerContainer {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 2.4rem;
	gap: 1.6rem;

	& > h2 {
		flex: 1;
		@include textEllipsis();
	}

	& > button {
		padding: unset;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2rem 1.6rem;
	}
}

.closeIcon {
	path {
		stroke: var(--border-primary-color);
	}
}
