@import 'variables';
@import 'mixins';

.replyViewContainer {
	flex: 1;
	overflow: hidden;
	padding: 0.8rem 0rem 5.6rem 0rem;
	& > * {
		margin-left: 2.4rem;
		margin-right: 2.4rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem 0rem 5.6rem 0rem;

		& > * {
			margin-left: 1.6rem;
			margin-right: 1.6rem;
		}
	}
}

.inputContainer {
	border: 2px solid #2b333b;
	border-radius: 6px;
	padding: 1.2rem;
	max-height: calc(100% - 64px);
	overflow-y: auto;

	.textAreaInput {
		width: 100%;
		outline: none;
		border: none;
		background-color: transparent;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2rem;
		height: 144px;
		resize: none; /* Prevent manual resizing */
		caret-color: var(--text-primary-color);

		@media screen and (min-width: $mobile-screen-max-width) {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
		}

		&:focus-visible {
			outline: none;
			border: none;
		}

		&::placeholder {
			font-weight: 400;
			color: var(--text-tertiary-color-v0);
		}
	}

	.textAreaInputError {
		border-width: 1px;
		border-color: var(--error-color);
	}
}

.errorText {
	@include textEllipsis();
	margin-top: 0.8rem;
	height: 18px;
	line-height: 16px;
	margin-bottom: 2.4rem;
}

.infoText {
	@include textEllipsis();
	margin-top: 0.8rem;
	height: 18px;
	line-height: 18px;
	margin-bottom: 2.4rem;
}

.cannedResponseContainerWithChevron {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-top: 2.4rem;
	margin-left: unset;
	margin-right: unset;
	padding-left: 2.4rem;
	padding-right: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
}

.rightBoxShadow {
	position: absolute;
	right: 0;
	top: 0;
	width: 28px;
	height: 100%;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) -13.75%,
		rgba(255, 255, 255, 0.73822) 26.99%,
		#ffffff 70%
	);
	opacity: 0;

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 24px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.98) 58.8%,
			#ffffff 80%
		);
	}
}

.leftBoxShadow {
	@extend .rightBoxShadow;
	left: 0;
	right: unset;
	transform: rotate(180deg);
}

.showBoxShadow {
	opacity: 1;
}

.chevronLeft {
	position: absolute;
	left: 2rem;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	opacity: 0;
	transition: opacity 0.1s linear;
	z-index: 1;

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
		left: 1.2rem;
	}

	& > button {
		border: 1px solid var(--background-tertiary-color);
		background-color: var(--background-primary-color);
		border-radius: 999px;
		width: 2.4rem;
		min-width: 2.4rem;
		max-width: 2.4rem;
		height: 2.4rem;
		min-height: 2.4rem;
		max-height: 2.4rem;
		transform: rotate(180deg);
		padding: unset;

		.icon {
			& > path {
				background: #7b8c9e;
			}
		}
	}
}

.chevronRight {
	@extend .chevronLeft;
	left: unset;
	right: 2rem;
	transform: unset;

	@media screen and (max-width: $mobile-screen-max-width) {
		right: 1.2rem;
	}

	& > button {
		transform: unset;
		margin-left: unset;
	}
}

.showChevron {
	opacity: 1;
}

.cannedResponseContainer {
	display: flex;
	align-items: center;
	overflow: auto;
	flex-wrap: nowrap;
	gap: 0.8rem;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	& > button {
		padding: 0.8rem 1.2rem;
		border: 1px solid var(--border-secondary-color);
		border-radius: 99999px;
	}
}

.bookmarkActionContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.6rem;
	width: calc(100% - 3.2rem);
	margin-top: 1.6rem;

	&:focus-visible {
		outline: none;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
		width: calc(100% - 4.8rem);
	}

	.checkbox {
		height: 1.4rem;
		width: 1.4rem;
		border: 1.5px solid #9fa9b4;
		background-color: var(--background-primary-color);
		border-radius: 3px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		border-color: var(--brand-primary-color);
		background-color: var(--brand-primary-color);
	}

	& > p {
		width: 100%;
	}
}

.bookmarkActionContainerMargin {
	margin-top: 2.4rem;
}

.backBtn {
	border: 1px solid var(--border-secondary-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #f5f5f5;
		}
	}
}
