@import 'variables';

.swipeChatCardsContainer {
	height: calc(100% - 64px);
	display: flex;
	flex-direction: column;
	padding-bottom: 2rem;
	overflow: hidden;
}

.unreadCount {
	text-align: center;
	margin-top: 2rem;
}

.cardsContainer {
	flex: 1;
	position: relative;
	margin: 2.8rem 1.6rem 0 1.6rem;
}

.actionsContainer {
	padding: 1.2rem 2.4rem;
	height: 4.4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1.2rem;
	gap: 1.6rem;

	& > button {
		padding: unset;
		gap: 0.5rem;
	}

	& > button:last-child {
		& > span:nth-child(2) {
			transform: scaleX(-1);
		}
	}
}

.swipeableLoadingChatCard {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 10px 0px rgba(97, 94, 94, 0.16);
	transform: scale(0.95);
}

.emptyStateUndoBtn {
	padding: unset;
	margin-left: auto;
	margin-right: auto;
	gap: 0.6rem;
	margin-top: 22rem;
	margin-bottom: 2.4rem;

	& > span {
		display: inline-block;
	}
}
