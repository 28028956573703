@import 'mixins';
@import 'variables';

.messageStepContainer {
	position: relative;
}

.addingFileLoader {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);
}

.inputContainer {
	border-radius: 0.6rem;
	width: 100%;
	background-color: var(--background-primary-color);
	border: 2px solid var(--text-primary-color);
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: text;
	}

	& > div:first-child > button:first-child {
		margin-bottom: 1.2rem;
		background-color: var(--background-secondary-color);
		margin-left: 1.2rem;
		margin-right: 1.2rem;
	}

	.input {
		@include fontFamily();
		background-color: transparent;
		cursor: auto;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 500;
		border: none;
		resize: none; /* Prevent manual resizing */
		height: fit-content;
		max-height: 100px;
		width: 100%;
		align-self: center;
		caret-color: var(--text-primary-color);
		padding-left: 1.2rem;
		padding-right: 1.2rem;

		&:focus-visible {
			outline: none;
			border: none;
		}

		&::placeholder {
			color: var(--text-tertiary-color);
			font-weight: 300;
		}
	}

	.pdfContainer {
		display: grid;
		grid-template-columns: 1fr auto auto;
		grid-template-areas: 'selectedPdf countText addPdfBtn';
		width: 100%;
		margin-top: 1.2rem;
		gap: 1.6rem;
		height: 2.8rem;
		padding-left: 1.2rem;
		padding-right: 1.2rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			gap: 1.2rem;
		}

		.selectedPdfContainer {
			grid-area: selectedPdf;
			display: flex;
			flex: 1;
			align-items: center;
			width: fit-content;
			padding: 0.6rem 0.8rem;
			background-color: var(--background-secondary-color);
			border-radius: 6px;
			max-width: 160px;
			gap: 0.6rem;

			.pdfName {
				@include textEllipsis();
			}
		}

		& > p {
			grid-area: countText;
			justify-self: flex-end;
			align-self: flex-end;
		}

		& > button {
			grid-area: addPdfBtn;
			justify-self: flex-end;
			align-self: flex-end;
		}
	}
}

.pdfUploadBtn {
	padding: unset;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closePdfBtn {
	padding: unset;
	.closeIcon path {
		fill: var(--text-tertiary-color);
		stroke: var(--text-tertiary-color);
	}
}

.validationContainer {
	display: flex;
	justify-content: space-between;
	gap: 1.6rem;
	margin-top: 1.2rem;

	.nextBtn {
		border-radius: 4px;
		height: 3.2rem;
		width: 88px;
		min-width: 88px;
		max-width: 88px;
		text-align: center;
		margin-left: auto;

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 3.6rem;
		}
	}
}
