@import 'variables';
@import 'mixins';

.joinWaitListContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.joinWaitListCard {
	padding: 6.4rem 12rem;
	@include onboardingCardStyles();
	

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem 1.6rem 1.6rem 1.6rem;
		height: 100%;
		width: 100%;
		border-radius: unset;
	}

	& > h1 {
		margin-top: 3.2rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 2rem;
		}
	}

	& > p {
		margin-top: 1.2rem;
		max-width: 550px;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 0.8rem;
		}
	}
}

.noBoxShadow {
	box-shadow: unset;
}

.linkIconContainer {
	height: 12rem;
	width: 12rem;
	background-color: var(--background-blue-secondary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;

	.icon path g {
		stroke: var(--brand-tertiary-color);
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 8rem;
		width: 8rem;
	}
}

.joinBtn {
	width: 100%;
	max-width: 285px;
	padding: 1.2rem 2.4rem 1.2rem 2.8rem;
	gap: 0.8rem;
	margin-top: 3.2rem;
	border-radius: 8px;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem auto;
		max-width: unset;
	}
}

.arrowIcon {
	transform: rotate(45deg);

	path {
		stroke: var(--background-primary-color);
	}
}

.joinInfo {
	margin-top: 4.8rem;
	background-color: var(--background-blue-secondary-color);
	padding: 1.6rem 2.4rem;
	border-radius: 8px;
	display: flex;
	align-items: center;
	gap: 0.8rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 3.2rem;
		padding: 1.2rem 1.6rem;
	}
}
