.modalCustomClass {
	& > div:nth-child(1) {
		border-radius: 1.2rem;
	}
}

.appUpdateContentContainer {
	min-height: 296px;
	height: max-content;
	width: 100%;
	max-width: 328px;
	padding: 2.4rem;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.iconContainer {
		background: #0080ff1a;
		border-radius: 9999px;
		height: 80px;
		width: 80px;
		padding: 20px;

		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: block;
		}
	}

	& > h2 {
		margin-top: 2.4rem;
	}

	& > p {
		margin-top: 0.8rem;
	}

	.relaunchButton {
		margin-top: 2.4rem;
		border-radius: 0.8rem;
		height: 48px;
		width: 100%;
	}
}
