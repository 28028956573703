@import 'variables';
@import 'mixins';

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideIn {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: fixed;
	margin: auto;
}

.errorContainer {
	@extend .loadingContainer;
}

.discoveryLayout {
	position: fixed;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	background-image: url('../../../public/images/discoveryPageBgDesktop.png'); /* Path to your image */
	background-repeat: no-repeat; /* Prevents repeating the image */
	background-position: center center; /* Aligns the image to the center center */
	background-size: cover; /* Scales the image to cover the container */

	opacity: 0;
	animation: fadeIn 0.5s linear forwards 1;

	@media screen and (max-width: $mobile-screen-max-width) {
		background-image: url('../../../public/images/landingPageBgMobile.png');
		height: 100vh;
	}

	& > header,
	& > main {
		width: 100%;
		max-width: 1102px;
		margin-left: auto;
		margin-right: auto;
	}
}

.commonPadding {
	@media screen and (max-width: 1200px) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
}

.header {
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 88px;
	background-color: rgba(239, 241, 243, 0.9);
	backdrop-filter: blur(2px);
	z-index: 1;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 80px;
		background-color: rgba(229, 242, 255, 0.9);
	}

	.logoBtn {
		padding: unset;
	}

	.earlyAccessBtn {
		margin-left: auto;
		height: 40px;
		width: 152px;
		padding: unset;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		border: 1px solid #c8d1da;
		border-radius: 6px;

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 32px;
			width: 114px;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
		}
	}

	@extend .commonPadding;
}

.mainHeaderText {
	margin-top: 3.6rem;
	text-align: center;
	background: linear-gradient(90deg, #2b333b 0%, #4c5e70 100%) text;
	-webkit-text-fill-color: transparent;
	color: unset;

	@extend .commonPadding;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 1.6rem;
	}
}

.tabsContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.2rem;
	overflow-x: auto;
	margin-top: 3.2rem;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@extend .commonPadding;

	@media screen and (max-width: $mobile-screen-max-width) {
		justify-content: flex-start;
		gap: 0.8rem;
		margin-top: 2.4rem;
	}

	.tabBtn {
		padding: unset;
		padding-left: 1.2rem;
		padding-right: 1.2rem;
		height: 38px;
		border: 1px solid #c8d1da;
		border-radius: 9999px;
		transition: all 0.25s linear;

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 34px;
		}
	}

	.activeTabBtn {
		background-color: var(--text-primary-color);
		border-color: var(--text-primary-color);
	}
}

.usersListContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: fit-content;
	gap: 2.4rem;
	margin: 4rem auto;
	@extend .commonPadding;
	opacity: 0;
	transform: translateY(20px);
	transition: all 0.5s linear;
	animation: slideIn 0.5s linear 0.1s forwards 1;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 780px) {
		grid-template-columns: auto;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		margin: 2.4rem auto;
		gap: 1.6rem;
	}
}

.userListContainerMargin {
	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 3.2rem;
	}
}
