/* styles.css */
.SliderRoot {
	position: absolute;
	display: flex;
	align-items: center;
	right: 0;
	margin-top: 0.8rem;
}
.SliderRoot[data-orientation='vertical'] {
	display: flex;
	flex-direction: column;
	width: 32px;
	height: calc(100% - 1.6rem);
}

.SliderTrack {
	position: relative;
	flex-grow: 1;
}
.SliderTrack[data-orientation='vertical'] {
	width: 3px;
	border-radius: 999px;
	background: #f2f4f8;
	opacity: 0;
	transition: opacity 0.1s linear;
}

.SliderTrack[data-disabled] {
	opacity: 0;
}

.SliderRange {
	position: absolute;
	background-color: #f2f4f8;
}
.SliderRange[data-orientation='vertical'] {
	width: 100%;
}

.SliderThumb {
	display: block;

	&:focus-visible {
		outline: none;
		border: none;
	}

	& > span:nth-child(1) {
		display: inline-block;
		margin-left: auto;
	}
}
