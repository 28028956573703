@import 'variables';
@import 'mixins';

.selectTrigger {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	padding-left: 0.8rem;
	background: transparent;
	height: 34px;

	@media screen and (min-width: $mobile-screen-max-width) {
		height: 36px;
		padding-right: 0.8rem;

		&:hover {
			cursor: pointer;
		}
	}

	&:focus-visible {
		outline: none;
		border: none;
	}
}

.selectHeader {
	font-size: 1.4rem;
	line-height: 2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}
}

.modalBtnStyles {
	padding: 1.2rem 2.8rem;
	width: 200px;
	margin-top: 2.4rem;
	border-radius: 0.8rem;
	min-height: 4.4rem;
	height: 48px;

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 100%;
	}
}

.confirmModalContentContainer {
	width: 100%;
	max-width: 420px;
	padding: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		max-width: unset;
	}

	.input {
		margin-top: 2.4rem;
		width: 100%;
		outline: none;
		border: 1px solid var(--border-secondary-color);
		font-size: 1.4rem;
		font-weight: 400;
		background-color: transparent;
		padding: 1.2rem 1.6rem;
		border-radius: 8px;
		line-height: 1.8rem;
		height: 48px;

		&::placeholder {
			color: var(--text-tertiary-color-v0);
		}
	}

	.buttonsContainer {
		display: flex;
		align-items: center;
		gap: 1.6rem;
	}

	.modalCancelBtn {
		@extend .modalBtnStyles;
		border: 1px solid var(--border-secondary-color);

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: var(--background-secondary-color);
			}
		}
	}

	.modalContinueBtn {
		@extend .modalBtnStyles;
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
