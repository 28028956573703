@import 'mixins';

.errorBoundaryContainer {
	@include oauthCallbackCardStyles();

	& > button {
		border-radius: 0.8rem;
		margin-top: 1.6rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 0.8rem 1.6rem;
			& > span {
				font-size: 1.2rem;
			}
		}
	}
}
