@import 'variables';
@import 'mixins';

.scrollChatCardContainer {
	width: 100%;
	overflow: hidden;
	transition: height 0.25s linear;
	position: relative;
	opacity: 1;
	padding-top: 2.4rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		background-color: var(--background-primary-color);
		border-radius: 1rem;
		border: 1px solid #ecf7fd;
		padding-bottom: 2.4rem;
		box-shadow: 0px 0px 8px 0px #5f70820a;
		margin-bottom: 1.6rem;
		cursor: pointer;

		&:hover {
			border: 1px solid #deecf3;
		}
	}
}

.closeScrollChatCard {
	opacity: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	border: none;
}

.profileInfoContainer {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'avatar name action' 'avatar headline action';
	column-gap: 1.2rem;
	align-items: center;
	margin-bottom: 1.2rem;
	margin-left: 1.6rem;

	// @media screen and (min-width: $mobile-screen-max-width) {
	// 	grid-template-columns: auto 1fr;
	// 	grid-template-areas: 'avatar name' 'avatar headline';
	// 	margin-right: 2.4rem;
	// 	margin-left: 2.4rem;
	// }

	.profileAvatar {
		grid-area: avatar;
		align-self: flex-start;
	}

	.profileTag {
		grid-area: tag;
		display: flex;
		align-items: center;
		gap: 0.4rem;
		margin-bottom: 0.4rem;

		& > span {
			display: inline-block;
		}
	}

	.profileName {
		grid-area: name;
		line-height: 22px;
		@include textEllipsis();
	}

	.bookmarkBtn {
		grid-area: action;
		padding: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1.6rem;
		align-self: flex-start;

		.bookmarkIcon {
			& > path {
				fill: var(--text-secondary-color);
			}
		}

		.bookmarkIconActive {
			& > path {
				fill: var(--text-primary-color);
			}
		}
	}

	.profileHeadline {
		grid-area: headline;
		line-height: 18px;
		font-size: 1.3rem;
		margin-top: 0.4rem;
		margin-right: 1.6rem;
		@include textEllipsis();

		@media screen and (min-width: $mobile-screen-max-width) {
			font-weight: 400;
			font-size: 1.2rem;
			color: var(--text-secondary-color);
			margin-right: unset;
		}
	}
}

.profileContainerWithTag {
	grid-template-areas: 'avatar tag action' 'avatar name action' 'avatar headline action';

	// @media screen and (min-width: $mobile-screen-max-width) {
	// 	grid-template-areas: 'avatar tag' 'avatar name' 'avatar headline';
	// }
}

.sparkleTagIcon {
	path {
		fill: #c3911e;
	}
}

.bookmarkTagIcon {
	& > path {
		fill: #c3911e;
	}
}

.recentMessage {
	margin-top: 1.2rem;
	margin-right: 1.6rem;
	max-height: 231px;
	transition: max-height 0.3s linear;
	overflow: hidden;
	position: relative;

	display: -webkit-box;
	-webkit-line-clamp: 11;
	-webkit-box-orient: vertical;
	overflow: hidden;

	margin-left: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		margin-right: 2.4rem;
		margin-left: 2.4rem;
	}

	.label {
		position: relative;
		margin-right: 1rem;

		&::after {
			content: '';
			height: 2px;
			width: 2px;
			border-radius: 9999px;
			position: absolute;
			top: 50%;
			transform: translate(-50%, 0);
			margin-left: 5px;
			background-color: var(--text-secondary-color);
		}
	}

	.assignedLabelTextWithDot {
		display: inline-block;
		padding-right: 1.2rem;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			width: 0.2rem;
			height: 0.2rem;
			border-radius: 999px;
			background-color: var(--text-secondary-color);
			right: 0.5rem;
			top: 50%;
			transform: translate(0, -50%);
		}
	}

	.labelCountText {
		padding: 0.3rem;
		border-radius: 0.2rem;
		background-color: #f6f0ff;
		display: inline-block;
		margin-left: 0.4rem;
	}

	.showMoreText {
		display: inline-block;
		position: absolute;
		left: 5.2ch;
		bottom: 0px;
		width: calc(100% - 5.2ch);
		text-align: start;
		background-color: var(--background-primary-color);
		line-height: 21px;
		color: #b4bac0;
		font-weight: 500;
		font-size: 13px;

		& > span:nth-child(2) {
			font-size: initial;
			color: initial;
			font-weight: initial;
		}
	}
}

.recentMessageTime {
	display: inline-block;
	margin-top: 0.6rem;
	margin-bottom: 1.6rem;
	padding-left: 1.6rem;
	font-size: 1.1rem;
	line-height: 1.4rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		font-size: 1.2rem;
		line-height: 1.6rem;
		padding-left: 2.4rem;
	}
}

.actionsContainer {
	display: flex;
	align-items: center;
	margin-right: 1.6rem;
	margin-bottom: 3.6rem;
	margin-left: 1.6em;
	position: relative;

	@media screen and (min-width: $mobile-screen-max-width) {
		gap: 4rem;
		margin-bottom: unset;
		justify-content: center;
		margin-right: 0;
		margin-left: 0;

		.markChatAsReadText{
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
		}
	}

	.markAsReadBtn {
		gap: 0.4rem;
		justify-content: center;
		align-items: center;
		border-radius: 0.6rem;
		background-color: #edf6ff;
		margin-left: auto;
		height: 36px;
		width: 100%;
		padding: 0.8rem 1.2rem 0.8rem 1rem;

		& > span:nth-child(2) {
			display: inline-block;
			line-height: 20px;
		}
	}

	.quickReplyBtn {
		@extend .markAsReadBtn;
		gap: 0.2rem;
		background-color: var(--brand-primary-color);
		margin-left: 0.8rem;
	}

	.quickReplyBtnDesktop {
		@extend .markAsReadBtnDesktop;
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg path {
			stroke: var(--brand-primary-color); 
			fill: var(--brand-primary-color); 
		}

		&:hover {
			color: var(--text-primary-color); 
	
			svg path {
				stroke: var(--text-primary-color);
				fill: var(--text-primary-color);
			}
		}
	}

	.markAsReadBtnDesktop {
		padding: unset;
		gap: 0.6rem;

		& > span:nth-child(1) {
			margin-top: 0.1rem;

			& > svg path {
				stroke: var(--brand-primary-color);
				fill: var(--brand-primary-color);
			}
		}

		& > span:nth-child(2) {
			display: inline-block;
			line-height: 20px;
		}

		&:hover {
			& > span:nth-child(1) {
				& > svg path {
					stroke: var(--text-primary-color);
					fill: var(--text-primary-color);
				}
			}

			& > span:nth-child(2) {
				color: var(--text-primary-color);
			}
		}
	}

	.bookmarkBtnDesktop {
		@extend .markAsReadBtnDesktop;
		width: 110px;

		& > span:nth-child(1) {
			margin-top: unset;
		}
	}

	& > div:last-child {
		position: absolute;
		right: 0;
	}

	.arrowContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 4rem;
		width: 4rem;
		border-radius: 99999px;
		margin-right: 1.3rem;

		.arrowIcon {
			& > path {
				fill: var(--brand-primary-color);
			}
		}

		&:hover {
			background-color: var(--background-secondary-color);

			.arrowIcon {
				& > path {
					fill: var(--text-primary-color);
				}
			}
		}
	}
}

.withBorderContainer {
	width: calc(100% - 3.2rem);
	margin-left: auto;
	margin-right: auto;
	height: 1px;
	background-color: #e7f1f9;
	margin-top: 1.2rem;
	margin-bottom: 1.2rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		width: calc(100% - 4.8rem);
		margin-left: auto;
		margin-right: auto;
		margin-top: unset;
		margin-bottom: 2.4rem;
		background-color: #eef5f9;
	}
}
