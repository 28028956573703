@import 'variables';
@import 'mixins';

.headerContainer {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'logo searchBox profilePic';
	align-items: center;
	gap: 2.4rem;
	height: $app-header-height;

	& > span {
		display: inline-block;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem 1.6rem;
		gap: 1.2rem;
	}
}

.searchBox {
	grid-area: searchBox;
	display: flex;
	align-items: center;
	border-radius: 8px;
	width: 100%;
	max-width: 240px;
	border: 1px solid transparent;
	gap: 0.8rem;
	max-height: 42px;
	padding: 1.2rem;
	background-color: rgba(255, 255, 255, 0.1);

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
		&:focus-within {
			border: 1px solid transparent;
		}
	}

	&:focus-within {
		border: 1px solid rgba(255, 255, 255, 0.5);
	}

	.input {
		@include fontFamily();
		width: 100%;
		flex: 1;
		outline: none;
		border: 1px solid var(--border-secondary-color);
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
		background-color: transparent;
		outline: none;
		border: none;
		color: #fff;

		&:focus-visible {
			outline: none;
			border: none;
		}

		&::placeholder {
			color: #b2d9ff;
		}
	}

	button {
		padding: unset;
	}

	.searchBoxIcon {
		& > path {
			stroke: rgba(255, 255, 255, 0.5);
		}
	}
}

.searchIconBtn {
	justify-self: flex-end;
	padding: unset;
	margin-right: 0.8rem;

	.searchIcon {
		& > path {
			fill: var(--text-blue-tertiary-color);
		}
	}
}

.searchPlaceholderText {
	color: #b2d9ff;
}

.profileHeaderContent {
	grid-area: profilePic;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1.2rem;
	justify-self: flex-end;

	.usernameLinkBtn {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		height: 3.6rem;
		display: flex;
		align-items: center;
		gap: 1.2rem;
		border-radius: 0.8rem;
		background-color: var(--brand-color-btn-header);
		max-width: 300px;

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: var(--brand-color-btn-header-active);
			}
		}
		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 1rem;
		}
	}
}

.linkText {
	@include textEllipsis();
}

.bubbleBtn {
	animation: bubble 0.2s ease-in-out forwards;
}

@keyframes bubble {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0.75;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.logoBtn {
	padding: unset;
	grid-area: logo;
}

.headerMenuTrigger {
	display: inline-flex;
	border: none;
	outline: none;
	padding: unset;
	border-radius: 999px;
	background: transparent;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}

	&:focus-visible {
		outline: none;
		border: none;
	}
}

.headerMenuContent {
	overflow: hidden;
	background-color: var(--background-primary-color);
	border: 1px solid var(--border-tertiary-color);
	border-radius: 8px;
	height: fit-content;
	max-height: calc(100vh - 128px);
	width: calc(100vw - 3.2rem);
	max-width: 256px;
	margin-top: 0.6rem;
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	padding-top: 0.8rem;
	padding-bottom: 1rem;
	display: flex;
	flex-direction: column;

	.imageWithCopyLink {
		padding: 1.2rem 1.6rem 1rem 1.6rem;
		text-align: center;
		max-height: 132px;

		& > h3 {
			@include textEllipsis();
			margin-top: 1rem;
		}

		.imageLinkBtn {
			gap: 0.5rem;
			width: 100%;
			padding: unset;
			margin-top: 0.4rem;

			&:focus,
			&:focus-visible {
				outline: none;
				border: none;
			}

			svg path {
				fill: var(--text-secondary-color);
			}
		}
	}

	& > button {
		width: 100%;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		height: 4rem;
		gap: 1rem;
		justify-content: flex-start;

		& > span:nth-child(1) {
			padding: 0.4rem;
			path {
				stroke: var(--text-secondary-color);
			}
		}

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: var(--background-secondary-color);
			}
		}
	}
}

.backButton {
	padding: unset;
	margin-top: -0.2rem;
}

.appBackBtn {
	padding: unset;
}

.headerTxt {
	@include textEllipsis();
	font-size: 1.8rem;
	color: var(--text-blue-tertiary-color);
}

.switchAccountHeader {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
	width: calc(100% - 3.2rem);
	margin-left: auto;
	margin-right: auto;
}

.otherAccountsContainer {
	width: 100%;
	flex: 1;
	overflow-y: auto;
}

.popoverSectionsBorder {
	margin-bottom: 0.8rem;
	margin-top: 0.8rem;
	height: 1px;
	width: calc(100% - 3.2rem);
	margin-left: auto;
	margin-right: auto;
	background-color: var(--border-tertiary-color);
}

.otherAccountBtn {
	width: 100%;
	height: 4rem;
	padding: unset;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	gap: 1rem;

	& > span {
		display: block;
	}

	& > span:nth-child(2) {
		flex: 1;
		text-align: start;
		@include textEllipsis();
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.discoverBtn {
	padding: unset;
	gap: 0.8rem;
	margin-right: 1.6rem;
}
