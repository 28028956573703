@import 'variables';

.addViewContainer {
	width: 100%;
	height: 100%;
	max-height: 620px;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	padding-bottom: 1.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-bottom: 1.2rem;
	}
}

.header {
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	gap: 1.6rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	& > button {
		padding: unset;
	}

	& > h3 {
		flex: 1;
	}

	.rightIcon {
		transform: rotate(-135deg);

		path {
			fill: var(--text-secondary-color);
		}
	}

	.closeIcon {
		path {
			stroke: var(--text-tertiary-color);
			fill: var(--text-tertiary-color);
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		gap: 1.2rem;
	}
}

.inputContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.8rem;
	padding: 1.2rem 2.4rem;
	height: 4.8rem;

	& > button {
		padding: unset;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.4rem 1.8rem 1.4rem 1.6rem;
		height: 5.2rem;
	}

	.input {
		width: 100%;
		outline: none;
		border: none;
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 2.4rem;
		height: 48px;
		background-color: transparent;
		caret-color: var(--text-primary-color);

		&::placeholder {
			color: var(--text-tertiary-color-v0);
			font-weight: 400;
		}
	}

	.iconDisabled {
		path {
			fill: #e1e6eb;
			stroke: #e1e6eb;
		}
	}

	.iconActive {
		path {
			fill: var(--brand-primary-color);
			stroke: var(--brand-primary-color);
		}
	}
}

.errorText {
	margin-left: 2.4rem;
	margin-right: 2.4rem;
	margin-bottom: 1.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-left: 1.6rem;
		margin-right: 1.8rem;
		margin-bottom: 1.4rem;
	}
}

.labelsListContainer {
	flex: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	.label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.8rem;
		padding: 1.2rem 2.4rem;

		& > button {
			padding: unset;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 1.4rem 1.8rem;
		}
	}
}

.labelsListContainerWithBorder {
	border-top: 1px solid var(--background-secondary-color);
}
