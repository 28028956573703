.swipeableChatCard {
	height: 100%;
	width: 100%;
	border-radius: 12px;
	position: absolute;
	background-color: white;
	touch-action: none;
	overflow: hidden;
}

.feedbackContainer {
	height: 100%;
	width: 100%;
	background-color: transparent;
	position: absolute;
	z-index: 1;

	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	gap: 0.8rem;

	padding: 3.2rem;

	& > p {
		width: 96px;
	}

	.feedbackIcon {
		display: block;
		& > path {
			fill: var(--background-primary-color);
		}
	}
}

.hideFeedbackContainer {
	display: none;
}

.educationContainer {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 2;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.92) 53.33%),
		linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding-bottom: 5rem;

	.swipesContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-left: 1.6rem;
		padding-right: 1.6rem;

		& > div {
			width: 120px;
			text-align: center;

			& > h3 {
				margin-top: 1.6rem;
			}
		}

		& > div:nth-child(1) {
			margin-right: auto;

			.swipeLeftIcon {
				display: block;
				animation: infiniteSwipeLeft 1250ms linear infinite;
			}
		}

		& > div:nth-child(2) {
			margin-left: auto;

			.swipeRightIcon {
				display: block;
				animation: infiniteSwipeRight 1250ms linear infinite;
			}
		}
	}

	.educationButton {
		background-color: var(--text-primary-color);
		border-radius: 8px;
		height: 38px;
		width: 112px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 4.8rem;

		& > span {
			display: inline-block;
		}
	}
}

@keyframes infiniteSwipeLeft {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes infiniteSwipeRight {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(50%);
	}
	100% {
		transform: translateX(0);
	}
}
