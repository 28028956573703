@import 'variables';

$maxWidth: 1102px;

@keyframes slideIn {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes blurIn {
	from {
		opacity: 0;
		filter: blur(10px);
	}
	to {
		opacity: 1;
		filter: blur(0);
	}
}

.landingPageLayout {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	background-image: url('../../../public/images/landingPageBgDesktop.png'); /* Path to your image */
	background-repeat: no-repeat; /* Prevents repeating the image */
	background-position: center center; /* Aligns the image to the center center */
	background-size: cover; /* Scales the image to cover the container */
	padding-bottom: 96px;

	@media screen and (max-width: $mobile-screen-max-width) {
		background-image: url('../../../public/images/landingPageBgMobile.png');
		height: 100vh;
		padding-bottom: 24px;
	}
}

.headerContainer {
	display: flex;
	align-items: center;
	backdrop-filter: blur(2px);
	height: 88px;
	width: 100%;
	padding-left: calc((100% - $maxWidth) / 2);
	padding-right: calc((100% - $maxWidth) / 2);

	@media screen and (max-width: 1200px) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 72px;
		background-color: rgba(229, 242, 255, 0.9);
	}

	.headerBtnsContainer {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 1.6rem;

		& > button {
			padding: unset;
		}
	}
}

.mainContainer {
	width: 100%;
	max-width: $maxWidth;
	margin-left: auto;
	margin-right: auto;
}

.loginContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	margin-top: 160px;
	margin-bottom: 160px;

	opacity: 0;
	filter: blur(10px);
	animation: blurIn 0.6s linear 0.1s forwards 1;

	@media screen and (max-width: 1200px) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		margin-top: 112px;
		margin-bottom: 140px;
	}

	& > h1 {
		background: linear-gradient(90deg, #2b333b 0%, #4c5e70 100%) text;
		-webkit-text-fill-color: transparent;
		color: unset;
	}

	& > p {
		margin-top: 1.6rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 0.8rem;
		}
	}

	.loginBtnsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1.6rem;
		margin-top: 4rem;
		width: 100%;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 3.6rem;
			flex-direction: column;
		}

		.earlyAccessBtn {
			height: 4.8rem;
			width: 100%;
			max-width: 200px;
			border-radius: 0.6rem;
			background: linear-gradient(180deg, #2e97fe 0%, #2181e0 100%);

			@media screen and (max-width: $mobile-screen-max-width) {
				max-width: 294px;
			}
		}

		.signInBtn {
			height: 4.8rem;
			width: 100%;
			max-width: 200px;
			border-radius: 0.6rem;
			background: #ffffff66;

			@media screen and (max-width: $mobile-screen-max-width) {
				max-width: 294px;
				background: #ffffff99;
				border: 1px solid #f1f7ff;
			}
		}
	}
}

.loginContainerNoAnimation {
	opacity: 1;
	filter: blur(0);
	animation: unset;
}

.usersListContainer {
	display: grid;
	grid-template-rows: auto 1fr;
	width: fit-content;
	margin: auto;

	opacity: 0;
	transform: translateY(20px);
	transition: all 0.5s linear;
	animation: slideIn 0.5s linear 0.6s forwards 1;

	@media screen and (max-width: 1200px) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.listHeaderContainer {
		display: flex;
		align-items: center;

		& > button {
			padding: unset;
			gap: 0.6rem;
			margin-left: auto;
			margin-top: 0.3rem;

			.chevronIcon {
				transform: rotate(-90deg);
				path {
					fill: var(--text-tertiary-color);
				}
			}

			@media screen and (max-width: $mobile-screen-max-width) {
				gap: 0.4rem;
				margin-top: 0.1rem;
			}
		}
	}

	.usersList {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: fit-content;
		gap: 3.2rem;
		margin-top: 3.2rem;
		margin-left: auto;
		margin-right: auto;

		@media screen and (max-width: 1200px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 780px) {
			grid-template-columns: auto;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 2.4rem;
			gap: 1.6rem;
		}

		.cardCustomClass {
			height: 236px;

			& > span:last-child {
				margin-bottom: unset;
			}

			@media screen and (max-width: $mobile-screen-max-width) {
				height: 221px;
			}
		}
	}
}

.usersListContainerNoAnimation {
	opacity: 1;
	transform: translateY(0);
	animation: unset;
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
