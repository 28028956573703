@import 'variables';

.shareableLinkContainer {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);
}
