@import 'variables';

.profileSection {
	width: 320px;
	padding: 1.6rem;
	height: 100%;
	overflow: auto;

	@media screen and (max-width: $tab-screen-max-width) {
		display: none;
	}
}

.Intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	.nameWithIcon {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		margin-top: 0.8rem;
	}

	.pendingIcon path {
		fill: #da730b;
	}

	& > p {
		margin-top: 0.4rem;
	}
}

.experienceSection {
	margin-top: 2.4rem;
	margin-bottom: 3.2rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
}

.experienceInfo {
	display: flex;
	gap: 1.2rem;

	.experienceLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.experienceContent {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
	}

	.moreExperienceContent {
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
	}
}

.companyName {
	& > span::before {
		content: '';
		height: 3px;
		width: 3px;
		background-color: var(--text-primary-color);
		border-radius: 999px;
		margin-left: 0.6rem;
		margin-right: 0.6rem;
		margin-bottom: 0.2rem;
		display: inline-block;
	}
}

.yearsMonthText {
	@extend .companyName;
	& > span::before {
		background-color: var(--text-tertiary-color);
	}
}

.educationSection {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
}

.educationInfo {
	display: flex;
	gap: 1.2rem;

	.educationLogo {
		overflow: hidden;
		border-radius: 4px;
		& > img {
			border-radius: 4px;
		}
	}

	.educationContent {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
	}
}

.viewProfileBtn {
	width: 100%;
	border: 1px solid var(--background-tertiary-color);
	border-radius: 8px;
	padding: 1.2rem auto;
	margin-top: 2rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.viewLinkedInProfileBtn {
	padding: 1.2rem auto;
	width: 100%;
	margin-top: 0.6rem;
	gap: 0.8rem;
}
