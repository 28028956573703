@import 'variables';

.container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1.2rem;
}

.itemCommonStyles {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);
	border: 1.5px solid #c8d1da;
	min-width: 1.6rem;
	min-height: 1.6rem;
	max-width: 1.6rem;
	max-height: 1.6rem;
	margin-top: 0.1rem;
}

.itemActiveCommonStyles {
	border-color: var(--brand-primary-color);
	background-color: var(--brand-primary-color);
}

.checkBoxItem {
	border-radius: $border-radius;
}

.selectAllItem {
	border-radius: $border-radius;
	position: relative;

	&::before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		content: '';
		height: 1.5px;
		width: 6px;
		border-radius: 999px;
		background-color: var(--text-tertiary-color);
	}
}

.selectAllCheckIcon path {
	stroke: var(--text-tertiary-color);
}

.radioItem {
	border-radius: 999px;
}

.radioItemActive {
	position: relative;

	&::before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		content: '';
		height: 0.4rem;
		width: 0.4rem;
		border-radius: 999px;
		background-color: var(--background-primary-color);
	}
}

.selectAllItemActive {
	background-color: var(--background-primary-color);
	border: 1.5px solid #c8d1da;

	&::before {
		all: unset;
	}
}
