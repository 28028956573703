@import 'variables';
@import 'mixins';

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.loaderListContainer {
	height: 100%;
	width: 100%;
	padding: 3.2rem;

	animation: fadeIn 0.5s cubic-bezier(0.4, 0, 0.6, 1) forwards;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem;
	}
}

.chatLoaderContainer {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8rem;
	margin-bottom: 3.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		grid-template-columns: 1fr;
		margin-bottom: 2.4rem;
	}
}

.recentMessageLoader {
	@include animatePulse();
	& {
		width: auto;
		height: 16px;
		border-radius: 9999px;
		align-self: center;
		background: linear-gradient(270deg, #f0f3f5 0%, #e6eaed 100%);
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}
}

.chatLoaderContainer:nth-child(1) {
	.recentMessageLoader {
		width: 80%;
	}
}

.chatLoaderContainer:nth-child(2) {
	@media screen and (max-width: $mobile-screen-max-width) {
		& > div {
			& > div:last-child {
				width: 35%;
			}
		}
	}

	.recentMessageLoader {
		width: 40%;
	}
}

.chatLoaderContainer:nth-child(3) {
	@media screen and (max-width: $mobile-screen-max-width) {
		& > div {
			& > div:last-child {
				width: 35%;
			}
		}
	}

	.recentMessageLoader {
		width: 60%;
	}
}
