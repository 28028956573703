@import 'variables';

.rootLayoutScrollCardViewDesktop {
	// background: linear-gradient(180deg, #ffffff 0%, #d2ecfa 100%);
	background-color: var(--background-blue-secondary-color);
}

.rootLayoutNonScrollCardViewDesktop {
	background-color: var(--background-blue-secondary-color);
}

.messageLayout {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.inboxMainLayoutWrapper {
	flex: 1;
	overflow: hidden;
	border-top-right-radius: $inbox-main-layout-border-radius;
	border-top-left-radius: $inbox-main-layout-border-radius;
}

.inboxMainLayout {
	background-color: var(--background-primary-color);
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.inboxMainLayoutScrollMode {
	overflow: auto;
	display: block;
	overflow-x: hidden;
}

.inboxMainLayoutScrollCardView {
	background-color: #ffffffa3;

	& > div[id='filters'] {
		background: linear-gradient(180deg, #ffffff 0%, #f4f9fe 100%);

		& > div {
			& > div:first-child {
				background: linear-gradient(180deg, #ffffff 0%, #f4f9fe 100%);
			}

			& > div:last-child {
				background: linear-gradient(180deg, #ffffff 0%, #f4f9fe 100%);
			}
		}
	}
}

.inboxLayoutScroll {
	& > div[id='filters'] {
		border-bottom: 1px solid var(--border-tertiary-color);
	}
}

.loadingContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: var(--background-primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.inboxMainLayoutLoading {
	& > div {
		opacity: 0;
	}

	.loadingContainer {
		opacity: 1;
	}
}

.inboxMainLayoutLoaded {
	& > div {
		opacity: 1;
	}

	.loadingContainer {
		display: none;
		opacity: 0;
	}
}

.liteUserInboxLayout {
	margin-top: 1.6rem;
}

.inboxMainChild {
	flex: 1;
	position: relative;
	display: flex;
	overflow: hidden;
	border-top: 1px solid transparent;
}

.inboxMainChildOverviewMode {
	overflow: unset;

	& > div:nth-child(1) {
		overflow: unset;
	}
}

.inboxMainChildBorder {
	border-top: 1px solid var(--border-tertiary-color);
}

.chats {
	flex: 1;
	display: none;
}

.showChats {
	display: block;
}

.optionCount {
	margin-left: 0.4rem;
}

.countTextContainerWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;
	padding-left: 1.6rem;
	padding-right: 0.8rem;
	max-height: 1.8rem;

	.countTextContainer {
		display: flex;
		align-items: center;
		gap: 0.4rem;
		justify-content: flex-start;

		& > p {
			line-height: 1.6rem;
		}

		& > button {
			padding: 0px;
			align-items: center;
			$padding-left: 10px;
			gap: 0.2rem;

			.archiveCtaText {
				padding-left: $padding-left;
				display: inline-block;
				position: relative;

				&::before {
					content: '';
					width: 2.5px;
					height: 2.5px;
					border-radius: 9999px;
					top: 50%;
					transform: translateY(-50%);
					position: absolute;
					background-color: #afb6bd;
					left: 2px;
					margin-top: 1px;
				}
			}

			.archiveChevron {
				& > path {
					stroke: #afb6bd;
				}
			}
		}
	}

	.btnsContainer {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		justify-content: flex-start;

		& > button {
			padding: unset;
		}
	}
}

.countTextContainerWrapperMargin {
	margin-top: 0.4rem;
}

.countTextContainerCardView {
	max-width: $scroll-card-view-max-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: unset;
	padding-right: unset;
}
