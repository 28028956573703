@import 'mixins';
@import 'variables';

.noResultsContainer {
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	overflow: auto;
	padding-top: 248px;

	& > h1 {
		margin-top: 2.4rem;
		margin-bottom: 0.8rem;
	}

	& > p {
		font-size: 1.2rem;
		line-height: 1.6rem;
	}

	& > button {
		padding: 0px;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-top: 208px;
	}
}

.withBorder {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 0%;
		height: 1px;
		width: calc(100% - 3.2rem);
		background-color: var(--border-tertiary-color);
	}
}
