@import 'variables';

.emailSignifierMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 1.6rem;
	margin-top: 1.6rem;
}

.emailSignifierContainer {
	width: 100%;
	max-width: 320px;
	border-radius: 8px;
	padding: 1rem 1.2rem;
	background-color: #fff7e7;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}
}

.emailSignifierContainerInSwipeCard {
	padding: 1rem;
}

.onlyVisible {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.4rem;
	margin-top: 0.4rem;

	& > span {
		display: inline-block;
	}
}

.emailSignifierModalContent {
	padding-top: 2.4rem;
	padding-bottom: 1.6rem;
	display: flex;
	flex-direction: column;
	max-height: 500px;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-bottom: 2.4rem;
		max-width: 368px;
	}

	& > h3 {
		padding-left: 1.6rem;
		padding-right: 1.6rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
		}
	}

	& > p {
		margin-top: 1.6rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-bottom: 0.8rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
		}
	}

	.mailsContainer {
		flex: 1;
		overflow-y: auto;
		padding-left: 1.6rem;
		padding-right: 1.6rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			padding-left: 2.4rem;
			padding-right: 2.4rem;
		}

		& > span {
			display: block;
			margin-top: 0.2rem;
		}

		& > span:first-of-type {
			margin-top: unset;
		}
	}

	.closeButton {
		width: calc(100% - 3.2rem);
		padding: 1.4rem 2.4rem;
		height: 48px;
		border-radius: 8px;
		margin-top: 2.4rem;
		margin-left: 1.6rem;
		margin-right: 1.6rem;

		@media screen and (min-width: $mobile-screen-max-width) {
			width: calc(100% - 4.8rem);
			margin-left: 2.4rem;
			margin-right: 2.4rem;
		}
	}
}
