@import 'variables';
@import 'mixins';

.rootLayoutViewDesktop {
	background-color: var(--background-blue-secondary-color);
}

.messageLayout {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1.6rem 0 1.2rem 0;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 0 1.6rem 0.8rem 1.6rem;
	}

	.text {
		@include textEllipsis();
		padding: 1.2rem 1.6rem;
		color: var(--text-blue-tertiary-color);

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 1.2rem;
			padding-left: 0.4rem;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		& > span {
			color: inherit;
		}
	}
}

.inboxMainLayoutWrapper {
	flex: 1;
	overflow: hidden;
	border-top-right-radius: $inbox-main-layout-border-radius;
	border-top-left-radius: $inbox-main-layout-border-radius;
}

.inboxMainLayout {
	background-color: var(--background-primary-color);
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	@media screen and (max-width: $mobile-screen-max-width) {
		border-radius: unset;
	}
}

.inboxMainLayoutOverviewMode {
	overflow: auto;
	display: block;
	overflow-x: hidden;
}

.loadingContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: var(--background-primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.inboxMainLayoutLoading {
	& > div {
		opacity: 0;
	}

	.loadingContainer {
		opacity: 1;
	}
}

.inboxMainLayoutLoaded {
	& > div {
		opacity: 1;
	}

	.loadingContainer {
		display: none;
		opacity: 0;
	}
}

.inboxMainChild {
	flex: 1;
	overflow: auto;
	position: relative;
	display: flex;
	overflow: hidden;
	border-top: 1px solid transparent;
}

.inboxMainChildOverviewMode {
	overflow: unset;

	& > div:nth-child(1) {
		overflow: unset;
	}
}

.inboxMainChildBorder {
	border-top: 1px solid var(--border-tertiary-color);
}

.chats {
	flex: 1;
	display: none;
}

.showChats {
	display: block;
}

.backBtn {
	padding: unset;
	margin-top: 1px;
}

.countTextContainer {
	margin-left: 1.6rem;
	margin-right: 1.6rem;
	margin-top: 0.4rem;
	display: flex;
	align-items: center;
	gap: 0.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		justify-content: space-between;
		margin-top: 0.8rem;
	}

	& > p {
		line-height: 1.6rem;
	}
}
