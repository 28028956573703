@import 'variables';
@import 'mixins';

.verifyLinkedInAlert {
	border: 0px solid #facc9e;
	background: #da730b;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.2rem;
	width: 100vw;
	gap: 0.6rem;
	height: 5.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-top: 1.4rem;
		padding-top: 1.4rem;
		height: 5.6rem;
		& > span {
			display: block;
			align-self: flex-start;
		}
	}
}

.verifyNowBtn {
	background-color: var(--background-primary-color);
	border-radius: 8px;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	height: 28px;
	margin-left: 0.6rem;

	& > span {
		color: #da730b;
		text-wrap: nowrap;
	}
}
