.loaderContainer {
	aspect-ratio: 1;
	height: 2rem;
	height: 100%;
	border-color: var(--background-tertiary-color);
	border-radius: 999px;
	border-top-color: var(--brand-primary-color);
	border-left-color: var(--brand-primary-color);
	animation: spin 1s linear infinite;
	margin: auto;
	border-style: solid;
}

.white {
	border-color: var(--brand-primary-color);
	border-top-color: var(--background-primary-color);
	border-left-color: var(--background-primary-color);
}

.allWhite {
	border-color: rgba(255, 255, 255, 0.25);
	border-top-color: var(--background-primary-color);
	border-left-color: var(--background-primary-color);
}

.black {
	border-color: var(--border-secondary-color);
	border-top-color: var(--text-primary-color);
	border-left-color: var(--text-primary-color);
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
