.modalContainer {
	& > div:nth-child(1) {
		max-height: 90%;
		overflow: hidden;

		& > div:nth-child(1) {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
