@import 'variables';

.bookmarkNudgeContainer {
	width: 100%;
	max-width: 400px;
	padding: 1.6rem;
	border-radius: 8px;
	background-color: #fff7e7;
	border: 1px solid #fbeed4;
	margin-bottom: 2rem;
	display: none;

	& > p {
		margin-top: 0.4rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: 276px;
	}
}

.displayBookmarkNudgeContainer {
	display: block;
}

.bookmarkSuccessContainer {
	background-color: #fff7e7;
	padding: 1rem 1.2rem;
	width: fit-content;
	margin: auto;
	border-radius: 8px;
	margin-bottom: 2rem;
	margin-right: auto;
}

.bookmarkNudgeButtonContainer {
	display: flex;
	align-items: center;
	gap: 1.6rem;
	margin-top: 1.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 0.8rem;
	}
}

.cancelButton {
	height: 3.6rem;
	border-radius: 6px;
	text-align: center;
	padding: auto;
	width: 100%;

	border: 1px solid rgba(66, 45, 7, 0.1);
	background: #fff7e7;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			border: 1px solid rgba(66, 45, 7, 0.1);
			background: #fff2d8;
		}
	}
}

.bookmarkButton {
	height: 3.6rem;
	border-radius: 6px;
	text-align: center;
	padding: auto;
	background-color: #422d07;
	gap: 0.5rem;
	width: 100%;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #2b2004;
		}
	}
}
