@import 'variables';
@import 'mixins';

.categoryBox {
	padding: 1.6rem;
	background-color: var(--background-secondary-color);
	border-radius: 0.8rem;
	max-width: 360px;
	width: 100%;
	height: 48px;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}

	&:active {
		opacity: 0.9;
	}
}

.categoryInputContainer {
	display: flex;
	align-items: center;
	gap: 1.2rem;

	.input {
		@include fontFamily();
		flex: 1;
		outline: none;
		border: none;
		border-bottom: 1px solid var(--border-secondary-color);
		font-size: 1.2rem;
		font-weight: 400;
		line-height: 1.6rem;
		background-color: transparent;
		caret-color: var(--text-primary-color);
		padding: 0.2rem;

		&::placeholder {
			color: var(--text-tertiary-color-v0);
		}
	}

	.dragIcon {
		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				cursor: grab;
			}
		}
	}

	.deleteBtn {
		padding: unset;
	}

	&:active {
	}
}

.inputContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.8rem;
	margin-top: 1.6rem;

	& > button {
		border-radius: 0.8rem;
		padding: 1.2rem 2.4rem;
	}

	.input {
		max-width: 360px;
		width: 100%;
		outline: none;
		border: 1px solid var(--border-secondary-color);
		font-size: 1.4rem;
		font-weight: 400;
		background-color: transparent;
		padding: 1.2rem 1.6rem;
		border-radius: 0.8rem;
		line-height: 1.8rem;

		&::placeholder {
			color: var(--text-tertiary-color-v0);
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}

.modalBtnStyles {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	border-radius: 0.8rem;
	height: 44px;
}

.alertModalContentContainer {
	width: 100%;
	max-width: 420px;

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}

	& > p {
		margin-top: 2.4rem;
	}

	.buttonsContainer {
		display: flex;
		align-items: center;
		gap: 1.6rem;
	}

	.modalCancelBtn {
		@extend .modalBtnStyles;
		border: 1px solid var(--border-secondary-color);
		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: var(--background-secondary-color);
			}
		}
	}

	.modalContinueBtn {
		@extend .modalBtnStyles;
	}
}
