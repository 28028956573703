@import 'variables';

.container {
	padding: 2.4rem;
	width: 420px;
	max-width: 100%;

	& > p {
		margin-top: 0.8rem;
	}

	& > button {
		padding: unset;
		margin-top: 2.4rem;
		height: 40px;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		width: 100%;
		border-radius: 0.4rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		width: 100%;
		max-width: unset;
	}
}
