// declare css common utility classes

@import 'variables';

@mixin circle($size) {
	height: $size;
	width: $size;
	border-radius: 50%;
}

@mixin centerAbsoluteElement() {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

@mixin oauthCallbackCardStyles() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: fit-content;
	width: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 2rem;
	border: 2px solid var(--border-color);
	border-radius: 12px;
	padding: 6rem 3rem;
	background-color: var(--background-primary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.0705882353);

	& > div:nth-child(1) {
		height: 3.6rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
		width: 100%;
		height: 100%;
		gap: 0.8rem;

		& > div:nth-child(1) {
			margin: unset;
		}

		& > h1 {
			font-size: 2rem;
		}

		& > p {
			font-size: 1.6rem;

			& > span {
				font-size: 1.6rem;
			}
		}
	}
}

@mixin onboardingCardStyles() {
	height: 100%;
	height: 500px;
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--background-primary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	border-radius: 2.4rem;

	overflow: auto;
	margin: auto;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
		height: 480px;
	}
}

@mixin textEllipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin disableUserSelect() {
	p,
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

@mixin fontFamily() {
	font-family:
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		Inter,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica,
		Arial,
		sans-serif;
}

@mixin animatePulse() {
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
	@keyframes pulse {
		50% {
			opacity: 0.5;
		}
	}
}
