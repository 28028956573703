@import 'variables';
@import 'mixins';

.pageContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: auto;
	width: 100%;

	@include disableUserSelect();
}

.pageContainerMobile {
	max-width: $inbox-chat-user-info-max-width;
	width: 100%;
	@media screen and (max-width: $mobile-screen-max-width) {
		width: 0;
	}
}

.borderEle {
	height: 1px;
	width: 100%;
	top: 0;
	position: sticky;
}

.addBackgroundColor {
	background-color: var(--border-tertiary-color);
}

.chatRow {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #f9f9f9;
			cursor: pointer;
		}
	}
}

.firstChatRow {
	& > div:nth-child(1) {
		padding-top: 1.6rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding-top: 2rem;
		}
	}

	& > article:nth-child(1) {
		padding-top: 1.6rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding-top: 2rem;
		}
	}
}

.label {
	background-color: #e5f2ff;
	padding: 0.4rem 1.2rem;
	border-radius: 999px;
	color: #488cd1;
	margin-right: 1.2rem;
}

.lastMessage {
	flex: 1;
	padding: 1.2rem 1.6rem;
	@include textEllipsis();

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}
}

.dateDisplay {
	padding-left: 1.6rem;
	padding-right: 3.2rem;
	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}
}

.loadingContainer {
	& > p {
		text-align: center;
		padding: 2.4rem;
	}
}

.chatReported {
	height: 0px;
	overflow: hidden;
}

.countWithBackBtn {
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: var(--background-primary-color);
	border-bottom: 1px solid var(--border-tertiary-color);
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	gap: 0.6rem;
	height: 5.4rem;
	min-height: 5.4rem;
	max-height: 5.4rem;

	.backIcon {
		& path {
			fill: var(--text-secondary-color);
		}
	}

	& > button {
		padding: unset;
	}
}
