@import 'mixins';
@import 'variables';

.scrollChatCardsContainer {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $mobile-screen-max-width) {
		margin-top: 1.6rem;
	}

	@include disableUserSelect();
}

.actionsContainerMargin {
	margin-top: 0.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 0.8rem;
	}
}

.cardsContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	@media screen and (min-width: $mobile-screen-max-width) {
		margin-top: unset;

		max-width: $scroll-card-view-max-width;
		margin-left: auto;
		margin-right: auto;
	}
}

.loadingContainer {
	& > p {
		text-align: center;
		padding: 2.4rem;
	}
}

.parentEleBackgroundChange {
	background-color: unset;
	background: linear-gradient(180deg, #f5fbfe 0%, #ffffff 100%);
}
