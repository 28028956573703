.statusBarContainer {
	display: flex;
	gap: 0.8rem;

	.statusBar {
		width: 100%;
		height: 0.4rem;
		border-radius: 0.8rem;
		background-color: var(--background-blue-secondary-color);
		overflow: hidden;

		& > span {
			display: block;
			background-color: var(--brand-primary-color);
			width: 0;
			height: 100%;
		}
	}
}

.fillStatusBar {
	& > span:nth-child(1) {
		width: 100%;
	}
}
