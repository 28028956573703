@import 'variables';
@import 'mixins';

:root {
	// declare all the css variables for colors
	--brand-color-btn-header-active: #327ae6;
	--brand-color-btn-header: #1a65d6;
	--brand-primary-color-active: #0066cc;
	--brand-primary-color: #0080ff;
	--brand-secondary-color: #0066cc;
	--brand-tertiary-color: #99ccff;
	--text-primary-color: #2b333b;
	--text-tertiary-color: #7b8c9e;
	--text-secondary-color: #5f7082;
	--text-tertiary-color-v0: #98a6b3;
	--text-disabled-color: #b2bfcc;
	--text-blue-primary-color: #0080ff;
	--text-blue-secondary-color: #488cd1;
	--text-blue-tertiary-color: #93bfec;
	--background-blue-primary-color: #0080ff;
	--background-blue-secondary-color: #e5f2ff;
	--background-blue-tertiary-color: #eef6ff;
	--background-primary-color: #ffffff;
	--background-secondary-color: #f2f4f7;
	--background-tertiary-color: #e6eaef;
	--border-primary-color: #7b8c9e;
	--border-secondary-color: #e0e6eb;
	--border-tertiary-color: #f2f4f7;
	--border-blue-primary-color: #cce2ff;
	--border-blue-secondary-color: #cce2ff;
	--google-btn-hover-color: #2866cc;
	--google-btn-color: #4285f4;
	--linkedIn-btn-color: #007ebb;
	--linkedIn-btn-hover-color: #006699;
	--error-color: #ec5050;
	--error-hover-color: #d62929;
	--success-color: #0ac184;
	--filter-background-color: #ffffff;
	--filter-border-color: #dadada;
	--filter-text-color: #808487;
	--filter-icon-color: #808487;
	--filter-active-background-color: #f9f9f9;
	--filter-active-border-color: #a9a9a9;
	--filter-active-text-color: #2b333b;
	--filter-active-icon-color: #2b333b;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: $global-font-size;
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

input,
select,
textarea {
	@include fontFamily();
	color: var(--text-primary-color);
}

button {
	white-space: nowrap;
}
