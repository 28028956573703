@import 'variables';

.settingsSection {
	margin-top: 4rem;
	margin-bottom: 4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 2.4rem;
		margin-bottom: 2.4rem;
	}

	& > p {
		margin-top: 0.6rem;
	}
}

.btnsContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 1rem;
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
}

.commonBtnStyles {
	border-radius: 0.8rem;
	gap: 0.6rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	height: 3.2rem;
}

.editBtn {
	@extend .commonBtnStyles;
	border: 1px solid var(--border-secondary-color);

	.editIcon {
		path {
			fill: var(--text-primary-color);
		}
	}

	.editIconDisabled {
		path {
			fill: var(--text-disabled-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.cancelBtn {
	@extend .commonBtnStyles;
	border: 1px solid var(--border-secondary-color);
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.saveBtn {
	@extend .commonBtnStyles;
	border-width: 1px;
	border-style: solid;
}
