@import 'variables';

.inboxLayoutMobile {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	position: relative;
	background-color: var(--background-primary-color);

	& > header {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	& > div[id='filters'] {
		top: $app-header-height;
	}
}

.inboxLayoutMobileScrollMode {
	& > div[id='filters'] {
		border-bottom: 1px solid var(--border-tertiary-color);
	}
}

.inboxLayoutMobileChatView {
	@extend .inboxLayoutMobile;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.inboxLayoutSwipeView {
	@extend .inboxLayoutMobileChatView;
	background: linear-gradient(358.47deg, #d4e5ee 38.86%, #d4e5ee 78.5%, #fdfdfd 105.54%);

	& > div[id='filters'] {
		background-color: transparent;
	}

	.inboxLayoutSwipeViewHeader {
		height: 64px;
		padding: 2rem 1.6rem;

		& > button {
			padding: unset;
			gap: 1.2rem;
		}

		.closeIcon {
			path {
				stroke: var(--background-primary-color);
			}
		}
	}
}

.inboxLayoutSwipeViewEmptyState {
	background: unset;
	background-color: var(--background-primary-color);
}

.inboxLayoutMobileLoading {
	.loadingContainer {
		opacity: 1;
	}
}

.inboxLayoutMobileLoaded {
	.loadingContainer {
		display: none;
		opacity: 0;
	}
}

.mainInboxLayoutHeader {
	background: linear-gradient(180deg, #004ab9 100%, #0066ff 100%);
	position: sticky;
	top: 0;
	z-index: 1;
}

.loadingContainer {
	position: absolute;
	margin-top: $app-header-height;
	height: calc(100% - $app-header-height);
	width: 100%;
	background-color: var(--background-primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.lightUserLoadingContainer {
	height: 100%;
	margin-top: 0px;
}

.countTextContainerWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	max-height: 1.8rem;

	.countTextContainer {
		display: flex;
		align-items: center;
		gap: 0.4rem;
		justify-content: space-between;
		flex: 1;

		& > p {
			line-height: 1.6rem;
		}

		& > button {
			padding: 0px;
			align-items: center;
			gap: 0.2rem;

			.archiveCtaText {
				display: inline-block;
				color: #afb6bd;
			}

			.archiveChevron {
				& > path {
					stroke: #afb6bd;
				}
			}
		}
	}

	.btnsContainer {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		justify-content: flex-start;

		& > button {
			padding: unset;
		}
	}
}

.countTextContainerWrapperMargin {
	margin-top: 0.8rem;
}

.countTextContainerWrapperUnreadSelected {
	padding-right: 0.8rem;
}
