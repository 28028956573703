@import 'mixins';

.addOrEditViewContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.inputsContainerWrapper {
		flex: 1;
		padding: 2.4rem 1.6rem 0 1.6rem;
		display: flex;
		flex-direction: column;
		overflow: auto;

		.inputContainer {
			display: flex;
			flex-direction: column;

			.label {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1.6rem;

				& > span {
					display: inline-block;
					line-height: 22px;
				}

				& > p {
					@include textEllipsis();
					line-height: 18px;
				}

				.deleteBtn {
					padding: unset;
				}
			}

			.errorText {
				@include textEllipsis();
				margin-top: 0.4rem;
				margin-bottom: 0.4rem;
				height: 16px;
				line-height: 16px;
			}
		}
	}
}

.input {
	width: 100%;
	outline: none;
	border: 1px solid var(--border-secondary-color);
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 2rem;
	padding: 1.2rem 1.6rem;
	background-color: transparent;
	border-radius: 8px;
	margin-top: 0.8rem;
	caret-color: var(--text-primary-color);
	height: 44px;

	&::placeholder {
		font-weight: 400;
		color: var(--text-tertiary-color-v0);
	}
}

.inputErrorState {
	border-color: var(--error-color);
}

.textAreaInput {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 2rem;
	@extend .input;
	height: 96px;
	resize: none; /* Prevent manual resizing */

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		font-weight: 400;
		color: var(--text-tertiary-color-v0);
	}
}

.bookmarkActionContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.6rem;
	width: 100%;

	.checkbox {
		height: 1.6rem;
		width: 1.6rem;
		border: 1.5px solid var(--brand-primary-color);
		background-color: var(--background-primary-color);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		background-color: var(--brand-primary-color);
	}

	& > p {
		line-height: 1.6rem;
		width: 100%;
	}
}

.buttonsContainerWrapper {
	padding: 1.6rem;
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;
	margin-top: 1.6rem;

	.backBtn {
		height: 48px;
		width: 100%;
		border: 1px solid var(--border-secondary-color);
		border-radius: 8px;
		padding: unset;

		& > span:nth-child(2) {
			line-height: 22px;
		}
	}

	.saveBtn {
		height: 48px;
		width: 100%;
		padding: unset;
		border-radius: 8px;
		& > span:nth-child(2) {
			line-height: 22px;
		}
	}
}

.scrollBorderTop {
	border-top: 1px solid var(--border-tertiary-color);
}
