@import 'variables';

.emptyStateContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	padding: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 4rem 1.6rem 1.6rem 1.6rem;
	}

	& > button:first-child {
		margin-left: auto;
		padding: unset;
	}

	.labelIconContainer {
		background-color: #ebf5ff;
		height: 8rem;
		width: 8rem;
		border-radius: 99999px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: inline-block;
		}
	}

	& > h3 {
		margin-top: 2.4rem;
	}

	& > p {
		margin-top: 0.4rem;
	}

	& > button:last-child {
		margin-top: 2.4rem;
		height: 4.8rem;
		border-radius: 0.8rem;
		width: 100%;
		gap: 0.6rem;

		.plusIcon {
			path {
				stroke: var(--background-primary-color);
			}
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 3.2rem;
		}
	}

	.closeIcon {
		path {
			stroke: var(--text-tertiary-color);
			fill: var(--text-tertiary-color);
		}
	}
}
