@import 'mixins';

.profileInfoContainer {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-areas: 'avatar name action' 'avatar headline action' 'avatar headline action';
	column-gap: 1.2rem;
	align-items: center;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	.profileAvatar {
		grid-area: avatar;
		align-self: flex-start;
	}

	.profileName {
		grid-area: name;
	}

	.action {
		grid-area: action;
		align-self: flex-start;
	}

	.profileHeadline {
		@include textEllipsis();
		grid-area: headline;
		margin-top: 0.4rem;
		overflow: hidden;

		.labelText {
			display: block;
			margin-top: 0.4rem;

			.labelCountText {
				padding: 0.2rem 0.3rem;
				border-radius: 0.2rem;
				background-color: #f6f0ff;
				display: inline-block;
				margin-left: 0.4rem;
			}
		}
	}
}
