@import 'variables';

.callbackContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--background-primary-color);
	text-align: center;
	padding: 1.6rem;
}

.feedbackContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.iconContainer {
		height: 9.6rem;
		width: 9.6rem;
		min-width: 9.6rem;
		min-height: 9.6rem;
		max-width: 9.6rem;
		max-height: 9.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& > h1 {
		margin-top: 2.4rem;
		line-height: 2.4rem;
	}

	& > p {
		margin-top: 0.6rem;
		line-height: 2rem;
	}
}
