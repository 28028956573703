@import 'variables';
@import 'mixins';

.tagsContainerWithChevron {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.rightBoxShadow {
	position: absolute;
	right: 0;
	top: 0;
	width: 60px;
	height: 100%;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) -13.75%,
		rgba(255, 255, 255, 0.73822) 26.99%,
		#ffffff 70%
	);
	opacity: 0;

	@media screen and (max-width: $mobile-screen-max-width) {
		width: calc(24px + 1.6rem);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.98) 58.8%,
			#ffffff 80%
		);
	}
}

.leftBoxShadow {
	@extend .rightBoxShadow;
	left: 0;
	right: unset;
	transform: rotate(180deg);
}

.showBoxShadow {
	opacity: 1;
}

.chevronLeft {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	opacity: 0;
	transition: opacity 0.1s linear;
	z-index: 1;

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}

	& > button {
		border: 1px solid var(--background-tertiary-color);
		background-color: var(--background-primary-color);
		border-radius: 999px;
		width: 2rem;
		min-width: 2rem;
		max-width: 2rem;
		height: 2rem;
		min-height: 2rem;
		max-height: 2rem;
		transform: rotate(180deg);
		margin-left: 2.4rem;
		padding: unset;

		.icon {
			& > path {
				background: #7b8c9e;
			}
		}
	}
}

.chevronRight {
	@extend .chevronLeft;
	left: unset;
	right: 0;
	transform: unset;
	margin-right: 2.4rem;

	& > button {
		transform: unset;
		margin-left: unset;
	}
}

.showChevron {
	opacity: 1;
}

.tagsContainer {
	display: flex;
	flex-wrap: nowrap;
	overflow: auto;
	gap: 0.6rem;
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding-right: 1.6rem;
	padding-left: 1.6rem;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-right: 2.4rem;
		padding-left: 2.4rem;
	}
}

.tagContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.7rem 1.4rem 0.7rem 1.2rem;
	border-radius: 99999px;
	flex-shrink: 0;
	gap: 0.6rem;
	max-height: 30px;
	border: 1px solid #f3f3f3;
	background-color: var(--background-primary-color);

	.avatarContainer {
		flex-shrink: 0;
		border: 1px solid var(--border-tertiary-color);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 9999px;
		overflow: hidden;
	}

	& > p {
		@include textEllipsis();
		text-wrap: nowrap;
		font-size: 12px;
	}
}

.tagContainerCompanyOrEducation {
	padding: 0.7rem 1rem 0.7rem 1rem;
}
