@import 'mixins';
@import 'variables';

@keyframes slideUp52 {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.chatsFooter {
	padding: 1.2rem 1.6rem;
	position: relative;
	box-shadow: 2px 2px 24px 0px #834f2912;
	border-top: 1px solid #f2f4f7;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
	}
}

.chatsFooterWithoutBorderEffect {
	border-top-color: transparent;
	box-shadow: unset;
}

.chatsFooterBookMarkOpen {
	padding-top: unset;
}

.cannedResponseContainer {
	width: calc(100% - 9rem);
	border-radius: 0.8rem;
	border: 1px solid var(--border-tertiary-color);
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	background-color: var(--background-primary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	position: absolute;
	bottom: calc(100% - 0.6rem);
	max-height: 320px;
	overflow: auto;
}

.bookmarkActionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;
	width: 100%;
	animation: slideUp52 250ms linear 1 forwards;
	height: 52px;
	transform: translateY(100%);
	opacity: 0;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	.checkbox {
		height: 1.6rem;
		width: 1.6rem;
		border: 1.5px solid var(--brand-primary-color);
		background-color: var(--background-primary-color);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checkboxActive {
		background-color: var(--brand-primary-color);
	}

	& > p {
		font-size: 13px;
		line-height: 2rem;

		& > span {
			font-size: 13px;
			line-height: 2rem;
		}
	}
}

@keyframes slideUp {
	0% {
		height: 0px;
		transform: translateY(100%);
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		height: 44px;
		transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: $mobile-screen-max-width) {
	@keyframes slideUp {
		0% {
			height: 0px;
			transform: translateY(100%);
			opacity: 0;
		}
		75% {
			opacity: 0;
		}
		100% {
			height: 52px;
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.inputMainContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;

	.inputContainer {
		padding: 1.2rem;
		border-radius: 1.2rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 1.2rem;
		width: 100%;
		background-color: #f6f7f7;

		.inputBoxWithButton {
			display: flex;
			align-items: center;
			width: 100%;

			@media screen and (min-width: $mobile-screen-max-width) {
				gap: 0.6rem;
			}

			.textAreaWithPdf {
				flex: 1;

				@media screen and (max-width: $mobile-screen-max-width) {
					margin-right: 0.8rem;
				}
			}

			.selectedPdfContainer {
				display: flex;
				align-items: center;
				width: fit-content;
				padding: 0.6rem 0.8rem;
				background-color: var(--background-primary-color);
				border-radius: 6px;
				max-width: 160px;
				gap: 0.6rem;
				margin-top: 1.2rem;

				.pdfName {
					@include textEllipsis();
				}
			}

			.input {
				background-color: transparent;
				cursor: auto;
				font-size: 1.4rem;
				line-height: 2rem;
				font-weight: 400;
				border: none;
				resize: none; /* Prevent manual resizing */
				height: fit-content;
				max-height: 100px;
				width: 100%;
				align-self: center;
				caret-color: var(--text-primary-color);

				@media screen and (max-width: $mobile-screen-max-width) {
					font-size: 1.5rem;
					line-height: 2rem;
				}

				&:focus-visible {
					outline: none;
					border: none;
				}

				&::placeholder {
					color: var(--text-tertiary-color-v0);
					font-weight: 400;
				}
			}

			& > button {
				align-self: flex-end;
			}

			& > input {
				align-self: flex-end;
			}
		}
	}

	.sendBtn {
		border-radius: 8px;
		padding: 1.2rem;
		background-color: var(--brand-primary-color);
		align-self: flex-end;
	}

	.sendBtnDisabled {
		background-color: #f6f7f7;
	}
}

.sendIconActive path {
	fill: var(--background-primary-color);
}

.pdfUploadBtn {
	padding: unset;
}

.cannedResponseBtn {
	padding: unset;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-right: 0.8rem;
	}

	&:focus-visible {
		outline: none;
	}
}

.closePdfBtn {
	padding: unset;
}

.uploadLoaderModalCustomClass div {
	background-color: transparent;
}

.sendingText {
	margin-top: 1.6rem;
}

.validationText {
	margin-top: 0.8rem;
	line-height: 16px;

	& > span {
		line-height: 16px;
	}
}

.cannedResponseHorizontalViewCustomClass {
	height: 52px;
	transform: translateY(100%);
	opacity: 0;
	animation: slideUp52 250ms linear 1 forwards;
}
