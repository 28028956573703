@import 'variables';
@import 'mixins';

.swipeableChatCardContentContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: height 0.25s linear;
	position: relative;
	opacity: 1;
	display: flex;
	flex-direction: column;

	@include disableUserSelect();
}

.bookmarkBtn {
	padding: unset;
}

.chatTagText {
	width: 100%;
	text-align: center;
	background-color: #fffcf2;
	padding: 0.7rem 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;

	.sparkleIcon {
		display: inline-block;
		margin-top: -0.2rem;
		path {
			fill: #c3911e;
		}
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	margin-bottom: 1.2rem;
	position: relative;
	row-gap: 1rem;
}

.headerContainerMarginTop16 {
	margin-top: 1.6rem;
}

.messageContainerWrapper {
	display: flex;
	flex: 1;
	overflow: hidden;
	width: 100%;
	position: relative;
}

.marginBottom8 {
	margin-bottom: 0.8rem;
}

.messageContainer {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	overflow-anchor: none;
	flex: 1;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	padding-top: 1.2rem;

	.viewPreviousBtn {
		padding: unset;
		margin-bottom: 0.8rem;
	}

	.viewPreviousMarginBottom {
		margin-bottom: 1.6rem;
	}

	.label {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.8rem;
		width: 100%;
		text-align: center;
		padding: 0.8rem 1.6rem;
		margin-bottom: 0.4rem;
		&::before,
		&::after {
			content: '';
			width: 40px;
			border-top: 1px solid #e1edf3;
		}
	}

	.chatMainContainer {
		position: relative;
		width: 100%;
	}

	.chatContainer {
		width: calc(100% - 6px);
		max-width: fit-content;
		position: relative;
		margin-bottom: 0.8rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: auto;

		& > p {
			width: fit-content;
		}
	}

	.chatContainerColored {
		margin-left: auto;
		padding-right: unset;
		margin-right: unset;
		align-items: flex-end;
	}

	.chatContainerMarginBottom {
		margin-bottom: 1.6rem;
	}

	.textWithReaction {
		position: relative;
	}

	.reactionBtn {
		position: absolute;
		background-color: #f9f9f9;
		border-radius: 999px;
		padding: unset;
		bottom: 1.6rem;
		left: 100%;
		transform: translate(0, -50%);
		margin-left: 0.6rem;
		padding: 0.5rem;
		display: none;
		height: 2.4rem;
		width: 2.4rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			display: block;
		}
	}

	.selectedReactionBtn {
		@extend .reactionBtn;
		display: block;
		transform: unset;
		transform: scale(0);
		border-radius: 999px;
		top: calc(100% - 0.8rem);
		left: 0;
		padding: unset;
		border: 1px solid var(--background-primary-color);
		animation: zoomIn 150ms ease-in-out 0ms 1 forwards;
	}

	.selectReactionSameUser {
		left: calc(100% - 4.4rem);
	}

	@keyframes zoomIn {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.25);
		}
		100% {
			transform: scale(1);
		}
	}

	.content {
		border-radius: 0.8rem;
		background-color: #f9f9f9;
		font-size: 1.4rem;
		line-height: 2rem;
		padding: 1.2rem;
		white-space: pre-wrap;

		& > a {
			color: inherit;
		}
	}

	.contentColored {
		background-color: var(--brand-primary-color);
	}

	.textContentMargin {
		margin-bottom: 1.2rem;
	}

	.pdfAttachmentContainer {
		@extend .content;
		display: flex;
		align-items: center;
		gap: 0.8rem;
		margin-top: 0.6rem;
		max-width: 200px;

		@media screen and (min-width: $mobile-screen-max-width) {
			cursor: pointer;
		}

		.pdfFileName {
			@include textEllipsis();
			line-height: 2rem;
		}

		.downloadIconBlack path {
			stroke: var(--text-primary-color);
		}
	}

	.timeIconContainer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 0.25rem;
		margin-top: 0.6rem;
		font-size: 1.1rem;

		& > span {
			font-size: 1.1rem;
			display: inline-block;
			margin-left: 1.2ch;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				height: 0.15rem;
				width: 0.15rem;
				transform: translateY(-50%);
				background-color: var(--text-tertiary-color);
				border-radius: 9999px;
				left: -0.9ch;
			}
		}
	}

	.failedTextBtn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0.6rem;
		font-size: 1.1rem;
		padding: unset;
		width: 100%;
		gap: 0.2rem;
		color: var(--error-color);
		height: 1.4rem;

		& > p {
			font-size: 1.1rem;
		}
	}
}

.inputContainer {
	width: 100%;
	padding: 1.6rem;
	display: flex;
	align-items: center;
	gap: 0.8rem;

	.inputWithIcons {
		flex: 1;
		display: flex;
		align-items: center;
		background-color: #f6f7f7;
		border-radius: 8px;
		min-height: 4.4rem;
		height: fit-content;
		padding: 1.2rem;

		.input {
			height: 100%;
			cursor: auto;
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 400;
			resize: none; /* Prevent manual resizing */
			max-height: 100px;
			width: 100%;
			align-self: center;
			caret-color: var(--text-primary-color);
			border: none;
			background-color: transparent;
			flex: 1;

			&:focus-visible {
				outline: none;
			}

			&::placeholder {
				color: var(--text-tertiary-color-v0);
				font-weight: 400;
				@include textEllipsis();
			}
		}

		.cannedResponseBtn {
			padding: unset;

			&:focus-visible {
				outline: none;
			}
		}
	}

	.sendBtn {
		border-radius: 8px;
		padding: 1.2rem;
		height: 4.4rem;
		width: 4.4rem;
		background: var(--brand-primary-color);
		align-self: flex-end;

		.sendIconActive path {
			fill: var(--background-primary-color);
		}
	}

	.sendBtnDisabled {
		background: var(--background-secondary-color);
	}
}

.undoReactionAlertModalContentContainer {
	width: 100%;
	max-width: unset;

	& > p {
		margin-top: 0.8rem;
	}

	& button {
		min-width: 130px;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	gap: 1.6rem;
}

.modalUndoBtn {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	border-radius: 0.8rem;
	height: 44px;
}

.modalCancelBtn {
	padding: 1.2rem 2.8rem;
	width: 100%;
	margin-top: 2.4rem;
	border: 1px solid var(--border-secondary-color);
	border-radius: 0.8rem;
	height: 44px;
}

.bookmarkIconColored {
	path {
		fill: var(--text-primary-color);
	}
}

.emailSignifierMarginBottom {
	margin-bottom: 1.6rem;
}

.emailSignifierMarginBottomRecent {
	margin-bottom: 2rem;
}

.bookmarkNudge {
	margin-top: 1.6rem;
	margin-left: auto;
}
