@import 'variables';
@import 'mixins';

.chatHeader {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	border-bottom: 1px solid var(--border-tertiary-color);
	width: 100%;
	min-height: 5.4rem;

	display: flex;
	align-items: center;
	gap: 4.8rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		background: linear-gradient(180deg, #004ab9 100%, #0066ff 100%);
		border-bottom: unset;
		min-height: 6.4rem;
	}
}

.nameWithLabel {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	overflow: hidden;

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 0.4rem;
	}

	.withPending {
		display: flex;
		align-items: center;
		gap: 0.8rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			gap: 0.4rem;
		}

		& > h2 {
			@include textEllipsis();
		}
	}

	.assignedLabelsContentText {
		@include textEllipsis();
	}
}

.chatHeaderInfoContentMobile {
	flex: 1;
	display: flex;
	align-items: center;
	gap: 0.8rem;
	overflow: hidden;

	.mobileHeaderContent {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		overflow: hidden;
	}
}

.chatHeaderInfoContent {
	@extend .chatHeaderInfoContentMobile;
	display: none;
}

.showChatHeaderInfoContent {
	display: flex;
	align-items: center;
}

.pendingIcon {
	display: block;
	margin-top: 0.2rem;
	path {
		fill: #da730b;

		@media screen and (max-width: $mobile-screen-max-width) {
			fill: var(--background-primary-color);
		}
	}
}

.backButton {
	padding: unset;
}

.bookmarkBtn {
	padding: unset;
}

.bookmarkIcon {
	@media screen and (max-width: $mobile-screen-max-width) {
		& path {
			fill: var(--background-primary-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover path {
			fill: var(--text-primary-color);
		}
	}
}

.bookmarkIconActive {
	& path {
		fill: var(--brand-primary-color);
		stroke: var(--brand-primary-color);
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		& path {
			fill: var(--background-primary-color);
			stroke: var(--background-primary-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover path {
			stroke: var(--brand-primary-color-active);
			fill: var(--brand-primary-color-active);
		}
	}
}

.actionsContainer {
	display: flex;
	gap: 1.6rem;
	align-items: center;
}

.threedots {
	padding: unset;
}

.blockModalContentContainer {
	width: 100%;
	max-width: 420px;
	padding: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		max-width: unset;
	}

	& > p {
		margin-top: 0.8rem;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	gap: 1.6rem;

	& > button {
		height: 4rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		border-radius: 0.4rem;
		margin-top: 2.4rem;
		width: 100%;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 0.8rem;
	}
}

.modalBlockBtn {
	background-color: var(--error-color);
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--error-hover-color);
		}
	}
}

.modalCancelBtn {
	border: 1px solid var(--border-secondary-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.forwardToEmailBtn {
	padding: unset;
}

.forwardToEmailIcon {
	& path {
		fill: var(--text-secondary-color);
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover path {
			fill: var(--text-primary-color);
		}
	}
}

.forwardToEmailDropdownIcon {
	& path {
		fill: var(--text-primary-color);
	}
}

.menuButton {
	padding: unset;

	@media screen and (min-width: $mobile-screen-max-width) {
		all: unset;
		cursor: pointer;
		height: 2rem;
		width: 2rem;
		min-width: 2rem;
		max-width: 2rem;
		min-height: 2rem;
		max-height: 2rem;
	}

	&:focus-visible {
		outline: none;
	}
}

.menuContentDesktop {
	width: 184px;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	background-color: var(--background-primary-color);
	border: 1px solid var(--border-tertiary-color);
	box-shadow: 2px 2px 24px 0px #834f2912;
	border-radius: 0.8rem;
	margin-top: 0.8rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;

		& > button:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.menuItem {
	display: flex;
	align-items: center;
	gap: 0.8rem;
	height: 42px;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	width: 100%;
	justify-content: start;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 54px;
		gap: 1.2rem;
		padding: unset;
	}
}

.threeDotsIcon {
	@media screen and (max-width: $mobile-screen-max-width) {
		circle {
			fill: var(--background-primary-color);
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			circle {
				fill: var(--text-primary-color);
			}
		}
	}
}

.toastIcon path {
	stroke: var(--background-primary-color);
}
