@import 'variables';

.moreOptionsBtn {
	all: unset;
	width: 3.2rem;
	height: 3.2rem;
	max-height: 3.2rem;
	min-height: 3.2rem;
	min-width: 3.2rem;
	max-width: 3.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}
}

.moreOptionsPopoverContent {
	background-color: var(--background-primary-color);
	border: 1px solid var(--border-tertiary-color);
	border-radius: 0.8rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	box-shadow: 2px 2px 24px 0px #834f2912;
	width: calc(100vw - 3.2rem);
	max-width: 200px;
	margin-top: 0.4rem;

	& > button {
		height: 42px;
		padding: unset;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		width: 100%;
		justify-content: flex-start;

		&:hover {
			background-color: var(--background-secondary-color);
		}
	}
}

.optionsContainerMobile {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;

	& > button {
		width: 100%;
		height: 54px;
		padding: unset;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		justify-content: flex-start;
	}
}

.nudgeContainer {
	margin-top: 4rem;
	margin-bottom: 6.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 1.6rem;
	padding-right: 1.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 4.4rem;
		margin-bottom: 7.2rem;
	}

	& > h3 {
		margin-top: 2.4rem;
	}

	& > p {
		margin-top: 0.8rem;
	}

	.btnsContainer {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: center;
		gap: 1.2rem;
		margin-top: 2rem;
		width: 100%;

		& > button {
			padding: unset;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			border: 1px solid var(--background-tertiary-color);
			border-radius: 0.6rem;
			height: 3.6rem;
			width: 100%;
			max-width: 14rem;

			@media screen and (min-width: $mobile-screen-max-width) {
				&:hover {
					background-color: var(--background-secondary-color);
				}
			}
		}
	}
}

.nudgeContainerListView {
	margin-top: 4rem;
	margin-bottom: 6.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 5.6rem;
		margin-bottom: 7.2rem;
	}
}
