@import 'variables';
@import 'mixins';

.authModalContentContainer {
	width: 360px;
	padding: 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 100%;
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
	}
}

.authModalVerifyOTPContentContainer {
	@extend .authModalContentContainer;
	padding-top: 0.8rem;

	.mailTextContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.6rem;

		.mailText {
			@include textEllipsis();
			flex: 1;
		}

		.backBtn {
			padding: unset;
			& > span {
				text-decoration: underline;
			}
		}
	}
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2.4rem 2.4rem 0 2.4rem;
	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 0 1.6rem;
		justify-content: flex-start;
	}
}

.googleBtnSpacing {
	width: 100%;
	min-height: 32px;
	border-radius: 0.8rem;
	padding: 1.2rem 2.4rem;

	& > span:nth-child(1) {
		border-radius: 999px;
		overflow: hidden;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}
}

.googleBtn {
	background-color: var(--google-btn-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--google-btn-hover-color);
		}

		&:focus-visible {
			outline-color: var(--google-btn-hover-color);
		}
	}
}

.orText {
	text-align: center;
	width: 100%;
	display: block;
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	position: relative;
}

.input {
	width: 100%;
	outline: none;
	border: 1px solid var(--border-secondary-color);
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.8rem;
	padding: 1.5rem 1.6rem;
	height: 48px;
	background-color: transparent;
	border-radius: 12px;

	&::placeholder {
		color: var(--text-tertiary-color-v0);
		font-weight: 400;
	}
}

.inputErrorState {
	border-color: var(--error-color);
}

.otpInputsContainer {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.otpInput {
	@extend .input;
	height: 4.8rem;
	width: 4.8rem;
	text-align: center;
	font-size: 2.4rem;
	line-height: 3.2rem;
	font-weight: 500;
	margin-top: 2.4rem;
	padding: 0;
}

.otpInputErrorState {
	border-color: var(--error-color);
}

.errorText {
	@include textEllipsis();
	margin-top: 1rem;
	height: 14px;
	text-align: start;
}

.continueBtn {
	margin-top: 2.4rem;
	height: 48px;
	padding: auto;
	width: 100%;
	border-radius: 0.8rem;
}

.verifyBtn {
	@extend .continueBtn;
	visibility: visible;
	margin-top: 2.4rem;
}
