@import 'mixins';
@import 'variables';

.inboxErrorContainer {
	display: flex;
	flex-direction: column;
	text-align: center;
	height: 100%;
	width: 100%;
	padding-top: 360px;

	& > h1 {
		margin-bottom: 0.8rem;
		line-height: 2.4rem;
	}

	& > p {
		line-height: 2rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-top: 304px;
	}
}
