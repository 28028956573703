.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--background-primary-color);
}

.loaderContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.text {
	margin: 1.6rem;
}
