@import 'variables';

.bulkReplyModalContainer {
	& > div {
		height: 100%;
		max-height: 634px;
		& > div {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			width: 480px;
			height: 100%;
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		& > div {
			border-radius: unset;
			max-height: unset;

			& > div {
				width: 100%;
			}
		}
	}
}

.hideBulkReplyModal {
	@media screen and (min-width: $mobile-screen-max-width) {
		display: none;
	}
}

.confirmContainer {
	padding: 2.4rem;
	width: 480px;
	max-width: 100%;

	& > p {
		margin-top: 0.8rem;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 2.4rem 1.6rem 1.6rem 1.6rem;
		width: 100%;
		max-width: unset;
	}
}

.btnsContainer {
	display: flex;
	align-items: center;
	margin-top: 2.4rem;
	gap: 1.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 0.8rem;
	}

	& > button {
		width: 100%;
		height: 4rem;
		padding: unset;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		border-radius: 0.4rem;
	}

	& > button:nth-child(1) {
		border: 1px solid var(--border-secondary-color);

		@media screen and (min-width: $mobile-screen-max-width) {
			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
}
