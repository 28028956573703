@import 'mixins';

.stepMiniInfoContainer {
	height: 52px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 1.6rem;
	margin-bottom: 1.6rem;
	border-bottom: 1px solid var(--border-tertiary-color);
	gap: 1.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-bottom: 1.2rem;
		margin-bottom: 1.2rem;
	}

	& > p {
		@include textEllipsis();
		flex: 1;
	}

	& > button {
		padding: unset;
	}
}

.iconWithAnimation {
	transform: scale(0);
	animation: bubbleOnce 0.3s ease-in-out 0ms forwards;
}

@keyframes bubbleOnce {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}
