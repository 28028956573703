@import 'variables';

.selectTrigger {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $filter-border-radius;
	padding: 0.8rem 1rem 0.8rem 1.2rem;
	gap: 0.4rem;
	border: 1px solid var(--filter-border-color);
	background-color: var(--filter-background-color);
	height: fit-content;
	text-wrap: nowrap;
	height: 34px;

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}

	&:focus-visible {
		outline: none;
	}

	.selectIcon {
		height: 1.2rem;
		width: 1.2rem;
		transition: transform 0.2s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: inline-block;
		}

		path {
			stroke: var(--filter-icon-color);
		}
	}

	.selectIconActive path {
		stroke: var(--filter-active-icon-color);
	}

	& > span:nth-child(1) {
		white-space: nowrap;
		line-height: 1.8rem;
		color: var(--filter-text-color);
		font-weight: 500;
		font-size: 1.2rem;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		height: 36px;
		padding: 0.8rem 0.8rem 0.8rem 1.2rem;

		&:hover {
			cursor: pointer;
		}

		& > span:nth-child(1) {
			line-height: 2rem;
			font-weight: 400;
			font-size: 1.4rem;
		}
	}
}

.selectTriggerActive {
	background-color: var(--filter-active-background-color);
	border-color: var(--filter-active-border-color);

	& > span:nth-child(1) {
		color: var(--filter-active-text-color);
		font-weight: 600;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--filter-active-background-color);
		}
	}
}

.selectButtonActive {
	border: 1px solid var(--filter-active-border-color);

	.selectIcon {
		transform: rotate(-180deg);
	}
}

.selectContent {
	overflow: hidden;
	background-color: var(--background-primary-color);
	border-radius: $filter-border-radius;
	border: 1px solid var(--border-tertiary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	max-height: 300px;
	min-width: 160px;
	width: max-content;
	max-width: 300px;
	margin-top: 0.2rem;
	display: flex;
	flex-direction: column;
}

.hideSelectContent {
	display: none;
}

.searchContainer {
	border-radius: $filter-border-radius;
	border: 1px solid var(--border-secondary-color);
	padding: 0.8rem 1.2rem;
	display: flex;
	align-items: center;
	margin: 2rem 2.4rem 0 2.4rem;
	gap: 0.6rem;
	min-height: 40px;

	.input {
		outline: none;
		border: none;
		font-size: 1.4rem;
		font-weight: 400;
		background-color: transparent;
		color: var(--text-primary-color);
		margin-top: 0.1rem;

		&::placeholder {
			color: var(--text-tertiary-color-v0);
		}
	}
}

.optionsContainer {
	flex: 1;
	overflow: auto;
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;

	& > div {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
	}

	& > div:last-child {
		padding-bottom: 0.8rem;
	}
}

.selectItem {
	outline: none;
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			cursor: pointer;
		}
	}
}

.countContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--filter-active-text-color);
	border-radius: 999px;
	height: 1.6rem;
	min-width: 1.6rem;
	padding-left: 0.4rem;
	padding-right: 0.4rem;

	.count {
		text-align: center;
		vertical-align: middle;
		font-size: 1.1rem;
		line-height: 2rem;
		font-weight: 700;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		height: 2rem;
		min-width: 2rem;
		.count {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}
}

.noOptionsText {
	padding: 3.2rem 2.4rem;
	text-align: center;
}

.modalContainer {
	& > div:nth-child(1) {
		max-height: 90%;
		overflow: hidden;

		& > div:nth-child(1) {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}
}

.modalMinHeight {
	& > div:nth-child(1) {
		height: 575px;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.6rem;
	padding: 2.4rem 2.4rem 0.4rem 2.4rem;

	& > button {
		padding: unset;
	}
}

.showResultsBtn {
	width: auto;
	margin: 1.6rem 2.4rem;
	border-radius: 0.8rem;
	margin-top: 0px;
}

.modalLoaderContainer {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.7);
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closeIcon {
	& > path {
		stroke: #7b8c9e;
	}
}
