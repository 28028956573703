@import 'variables';

.replyStatusViewContainer {
	flex: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.iconContainer {
		height: 9.6rem;
		width: 9.6rem;
		min-width: 9.6rem;
		min-height: 9.6rem;
		max-width: 9.6rem;
		max-height: 9.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& > h3 {
		margin-top: 2.4rem;
	}
}

.doneBtn {
	margin: 1.6rem 2.4rem 1.6rem 2.4rem;
	width: calc(100% - 4.8rem);
	height: 4rem;
	padding: unset;
	border-radius: 0.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin: 1.6rem;
		width: calc(100% - 3.2rem);
	}
}
