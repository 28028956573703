@import 'mixins';
@import 'variables';

.onboardingMainLayout {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: auto;
	width: 100%;
	gap: 2.4rem;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	position: relative;

	background-color: #e6eef5;

	background-image: url('../../../public/images/onboardingBgDesktop.png'); /* Path to your image */
	background-repeat: no-repeat; /* Prevents repeating the image */
	background-position: bottom center; /* Aligns the image to the bottom center */
	background-size: cover; /* Scales the image to cover the container */

	@media screen and (max-width: $mobile-screen-max-width) {
		margin: unset;
		height: 100%;
		gap: 0.8rem;
		padding-top: unset;
		padding-bottom: unset;
		background-color: var(--background-primary-color);
		background-image: url('../../../public/images/onboardingBgMobile.png'); /* Path to your image */
		background-position: top center; /* Aligns the image to the center center */
	}
}

.onboardingMainLayoutHeader {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 846px;
	width: 100%;
	margin: auto;

	opacity: 0;

	& > h1 {
		color: #406b8c;
		font-size: 2.4rem;
		line-height: 2.8rem;

		& > button {
			display: inline-block;
			padding: unset;
			border-bottom: 1px dashed #406b8c;

			& > span {
				color: #406b8c;
				font-size: 2.4rem;
				line-height: 2.8rem;
			}
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
		padding: 1.6rem;

		& > h1 {
			font-size: 1.4rem;
			line-height: 2rem;

			& > button {
				& > span {
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.onboardingMainLayoutHeaderAnimation {
	opacity: 0;
	animation: fadeIn 0.4s ease-in 0ms 1 forwards;
}

.onboardingMainLayoutHeaderNoAnimation {
	opacity: 1;
}

.loginBtn {
	display: inline-block;
	padding: unset;

	& > span {
		font-size: 1.2rem;
		line-height: 1.6rem;
		font-weight: 500;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		& > span {
			line-height: 1.8rem;
			font-size: 1.3rem;
		}
	}
}

.onboardingLayout {
	flex: 1;
	height: 100%;
	width: 100%;
	max-width: 846px;
	overflow: hidden;
	margin: auto;

	border-radius: 0.8rem;
	box-shadow: 0px 0px 20px 12px #0c18250a;

	display: grid;
	grid-template-columns: 302px 1fr;
	grid-template-areas: 'receiverInfo onboardingSteps';

	opacity: 0;
	transform: translateY(30px);

	@media screen and (max-width: $mobile-screen-max-width) {
		display: block;
		padding: unset;
		border-radius: unset;
		box-shadow: unset;
	}
}

@keyframes fadeInSlideUp {
	from {
		opacity: 0;
		transform: translateY(30px);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

.onboardingLayoutNoAnimation {
	opacity: 1;
	transform: translateY(0px);
}

.onboardingLayoutAnimation {
	opacity: 0;
	transform: translateY(30px);
	animation: fadeInSlideUp 0.4s ease-in-out 0.7s 1 forwards;
}

.receiverInfoSection {
	grid-area: receiverInfo;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.45);
	padding: 2rem 1.6rem 2rem 2.4rem;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.receiverProfileContainer {
	flex: 1;

	.nameWithIcon {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
		gap: 0.4rem;

		& > span {
			margin-top: 0.1rem;
			display: inline-block;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 1.6rem;
		}
	}

	.textWithBlinkContainer {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-left: 0.4rem;
		margin-top: 1.6rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 0.8rem;
		}

		.updatesBlink {
			position: relative;
			display: block;
			min-width: 0.8rem;
			max-width: 0.8rem;
			min-height: 0.8rem;
			max-height: 0.8rem;
			width: 0.8rem;
			height: 0.8rem;
			background-color: #009966;
			border-radius: 9999px;

			&::after {
				content: '';
				position: absolute;
				top: 0%;
				left: 0%;
				width: 100%;
				height: 100%;
				animation: circle-pulse 2s infinite;
				border-radius: 9999px;
				-webkit-box-shadow: 0 0 0 0 #009966;
				box-shadow: 0 0 0 0 #009966;
			}
		}

		.updatesText {
			@include textEllipsis();
			width: 100%;
			font-size: 1.4rem;
			line-height: 1.8rem;
			color: #009966;

			@media screen and (max-width: $mobile-screen-max-width) {
				font-size: 1.3rem;
			}
		}
	}
}

@keyframes circle-pulse {
	0% {
		-webkit-transform: scale(0.95);
		transform: scale(0.95);
		-webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
		box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
	}
	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-box-shadow: 0 0 0 8px rgba(77, 170, 87, 0);
		box-shadow: 0 0 0 8px rgba(77, 170, 87, 0);
	}
	100% {
		-webkit-transform: scale(0.95);
		transform: scale(0.95);
		-webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
		box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
	}
}

.onboardingStepsSection {
	grid-area: onboardingSteps;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--background-primary-color);
	height: 100%;
	width: 100%;
	border-left: 1px solid var(--border-secondary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		border-left: unset;
		background-color: transparent;
	}
}

.introMessageContainer {
	flex: 1;
	padding: 1.6rem;
	overflow-y: auto;

	& > h3 {
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 600;
	}

	& > p {
		margin-top: 1.6rem;
		background-color: #f4f7fc;
		padding: 1.2rem;
		white-space: pre-wrap;
		max-width: 400px;
		width: calc(100% - 0.8rem);
		margin-left: 0.8rem;
		position: relative;
		border-radius: 0px 12px 12px 12px;

		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 400;

		&::before {
			content: '';
			position: absolute;
			height: 0.8rem;
			width: 0.8rem;
			top: 0;
			left: -0.8rem;
			background: url('../../../public/images/messagePointer.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		& > a {
			color: inherit;
			text-decoration: underline;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-left: unset;
			width: 100%;
			max-width: unset;
			margin-top: 2rem;
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-top: unset;
	}
}

.onboardingStepsContainer {
	box-shadow: 0px -4px 20px 0px #0000000a;
	border-top: 1px solid var(--border-secondary-color);
	padding: 1.6rem;
	height: auto;
	background-color: var(--background-primary-color);
	position: relative;
}

.sendDMStepContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--background-primary-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}
