@import 'variables';

.modalCustomClass {
	& > div {
		width: 90%;
		max-width: 480px;
	}
}

.verifyLinkedInModalContentContainer {
	width: 100%;
	max-width: 480px;
	background-color: var(--background-primary-color);
}

.textWithInfoContainer {
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	& > article {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
		max-width: unset;
	}

	& > p {
		margin-top: 2.4rem;
	}
}

.textWithBtnContainer {
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	background-color: var(--background-secondary-color);
}

.verifyLinkedInModalBtn {
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	height: 4.8rem;
	width: 100%;
	border-radius: 0.8rem;
	margin-top: 1.6rem;
	background-color: var(--linkedIn-btn-color);
	align-items: center;
	gap: 0.6rem;

	.linkedInIcon {
		padding: 0.2rem;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--linkedIn-btn-hover-color);
		}
	}
}
