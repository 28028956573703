.deleteViewContainer {
	display: flex;
	flex-direction: column;
	padding: 2.4rem 1.6rem 1.6rem 1.6rem;

	& > p {
		margin-top: 0.8rem;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;
	margin-top: 2.4rem;

	.backBtn {
		height: 48px;
		width: 100%;
		border: 1px solid var(--border-secondary-color);
		border-radius: 8px;
		padding: unset;
	}

	.deleteBtn {
		height: 48px;
		width: 100%;
		padding: unset;
		border-radius: 8px;
	}
}
