@import 'variables';

.scrollableLayout {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--background-blue-secondary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		background-color: var(--background-primary-color);
	}
}

.mainHeaderContainer {
	height: $app-header-height;
	background: linear-gradient(180deg, #004ab9 0%, #0066ff 85.36%);
	width: 100%;
	padding-left: $layout-left-margin;
	padding-right: $layout-right-margin;

	@media (max-width: $desktop-screen-max-width) {
		background: linear-gradient(180deg, #004ab9 100%, #0066ff 100%);
		padding-left: unset;
		padding-right: unset;
	}
}

.mainContainer {
	flex: 1;
	overflow: auto;
}
