@import 'src/styles/variables';

.emptyStateMainContainer {
	display: flex;
	flex-direction: column;
	background-color: var(--background-primary-color);
	
	@media screen and (min-width: $mobile-screen-max-width) {
		width: 420px;
		padding: 2.4rem;		
		.closeIconBtn {	
			width: 16px;
			height: 16px;
		}
	}

	.closeIconBtn{
		position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        width: 24px;
        height: 24px;
        
        cursor: pointer;

        & > svg {
            & > path {
                fill: var(--text-secondary-color);
            }
        }
	}
}

.emptyStateContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	margin: 4rem 1.6rem 1.6rem 1.6rem;

	.emptyIcon {
		height: 104px;
		width: 104px;
		border-radius: 9999px;
		background-color: #9acbff33;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: inline-block;
			& > svg {
				& > path {
					fill: var(--brand-primary-color);
				}
			}
		}
	}

	& > h2 {
		line-height: 24px;
		margin-top: 2.4rem;
	}

	& > p {
		margin-top: 0.4rem;
		line-height: 20px;
	}
}

.buttonsContainerWrapper {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-top: 2.4rem;
		padding-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;

	.addEmptyStateBtn {
		border-radius: 8px;
		padding: unset;
		height: 4.8rem;
		width: 100%;
		gap: 0.8rem;

		& > span:nth-child(1) {
			svg,
			path {
				stroke: var(--background-primary-color);
				fill: var(--background-primary-color);
			}
		}
	}
}
