@import 'variables';

.accountCreatedContainer {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto 1fr;
	justify-items: center;
	row-gap: 1.6rem;

	

	
	background-color: #e6eef5;
	background-image: url('../../../../public/images/onboardingBgDesktop.png'); /* Path to your image */
	background-repeat: no-repeat; /* Prevents repeating the image */
	background-position: bottom center; /* Aligns the image to the bottom center */
	background-size: cover; /* Scales the image to cover the container */

	&>div {
		align-self: flex-end;
	}

	&>p {
		align-self: flex-end;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		background-color: var(--background-primary-color);
		background-image: url('../../../../public/images/onboardingBgMobile.png'); /* Path to your image */
		background-position: top center; /* Aligns the image to the center center */
	}
}
