@import 'mixins';

.listViewContainer {
	display: flex;
	flex-direction: column;
	max-height: 384px;
	overflow: hidden;
	background-color: var(--background-primary-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		max-height: 282px;
	}
}

.content {
	flex: 1;
	overflow: auto;
	padding-top: 1.6rem;

	display: flex;
	flex-direction: column;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-top: unset;
	}
}

.cannedResponseOption {
	width: 100%;
	padding: 1.2rem 1.4rem 1.2rem 1.8rem;

	display: grid;
	grid-template-areas: 'heading arrow' 'info arrow';
	grid-template-columns: 1fr auto;
	column-gap: 2.4rem;
	row-gap: 0.4rem;

	&:focus {
		background-color: var(--background-secondary-color);
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		grid-template-areas: 'heading arrow';
		row-gap: 0rem;
		align-items: center;

		&:focus {
			background-color: unset;
		}
		&:hover {
			background-color: var(--background-secondary-color);
		}
	}

	.arrowContainer {
		grid-area: arrow;
		align-self: center;

		& > span {
			& > svg {
				& > path {
					stroke: var(--text-primary-color);
				}
			}
		}

		@media screen and (min-width: $mobile-screen-max-width) {
			align-items: center;
			& > span {
				& > svg {
					& > path {
						stroke: var(--text-secondary-color);
					}
				}
			}
		}
	}

	.heading {
		grid-area: heading;
		font-size: 18px;
		line-height: 24px;

		@media screen and (min-width: $mobile-screen-max-width) {
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			align-self: center;
		}
	}

	.info {
		grid-area: info;
		line-height: 20px;
		@include textEllipsis();

		@media screen and (min-width: $mobile-screen-max-width) {
			align-self: center;
		}
	}
}

.cannedResponseBlinkEffect {
	animation: blinkBackground 150ms ease-in-out;

	@keyframes blinkBackground {
		0% {
			background-color: inherit;
		}
		100% {
			background-color: var(--background-secondary-color);
		}
	}
}

.buttonsContainerWrapper {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
		margin-left: 1.4rem;
		margin-right: 1.4rem;
	}
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.6rem;

	.addBtnWithIcon {
		gap: 0.8rem;
		padding: unset;
		width: 100%;
		height: 23px;

		& > span:nth-child(2) {
			line-height: 22px;
		}

		& > span:nth-child(1) {
			svg,
			path {
				stroke: var(--brand-primary-color);
			}
		}

		@media screen and (min-width: $mobile-screen-max-width) {
			justify-content: flex-start;
			height: unset;

			& > span:nth-child(2) {
				font-size: 14px;
				font-weight: 600;
				line-height: 18px;
			}
		}
	}
}

.withBorder {
	border-top: 1px solid #e7f1f9;
	padding-bottom: 2.4rem;
	margin-top: 1.6rem;

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-bottom: unset;
		margin-top: unset;
		padding-bottom: 1.2rem;
		padding-top: 2rem;
		height: 50px;
	}
}
