@import 'variables';
@import 'mixins';

.insightCard {
	border-radius: 0.6rem;
	padding: 1.2rem;
	background-color: #d5f1ff;
	height: 78px;
	width: 268px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-shrink: 0;
	gap: 1.8rem;
	cursor: pointer;

	@media screen and (max-width: $mobile-screen-max-width) {
		cursor: unset;
		width: 192px;
		height: 96px;
	}

	& > h3 {
		@media screen and (max-width: $mobile-screen-max-width) {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		@media screen and (min-width: $mobile-screen-max-width) {
			@include textEllipsis();
		}
	}

	.countWithTag {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.6rem;

		& > p {
			@include textEllipsis();
		}

		.tagText {
			display: block;
			border-radius: 0.4rem;
			padding: 0.2rem 0.6rem;
			background-color: #c9e5f5;
			flex-shrink: 0;
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #c2ebff;

			.countWithTag {
				.tagText {
					background-color: #d2edfc;
				}
			}
		}
	}
}

.wideMode {
	@media screen and (min-width: $mobile-screen-max-width) {
		width: 296px;
	}
}
