@import 'variables';

.settingsContainer {
	width: 100%;
	max-width: 700px;
	background-color: var(--background-primary-color);
	box-shadow: 2px 2px 24px 0px rgba(131, 79, 41, 0.07);
	border-radius: 1.6rem;

	margin: auto;
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	position: relative;
	padding: 4rem 6.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		margin-top: unset;
		margin-bottom: unset;
		border-radius: unset;
		min-height: 100%;
		padding-top: 2.4rem;
		padding-bottom: 3.2rem;
	}
}

.divider {
	display: block;
	height: 1px;
	border-radius: 1px;
	background-color: var(--border-tertiary-color);
}

.moreSettingsBtn {
	padding: unset;
	margin-top: 4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		margin-top: 2.4rem;
	}
}
