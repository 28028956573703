@import 'variables';

.mainFiltersContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		padding-top: 1.6rem;
		padding-bottom: 1.2rem;
	}

	.mainFilter {
		background-color: transparent;
		outline: none;
		border: unset;
		padding: unset;
		position: relative;

		@media screen and (min-width: $mobile-screen-max-width) {
			height: 4.8rem;
		}

		& > span {
			line-height: 2.4rem;

			@media screen and (max-width: $mobile-screen-max-width) {
				line-height: 1.8rem;
			}
		}
	}

	.bookmarkFilter {
		width: 96px;

		@media screen and (min-width: $mobile-screen-max-width) {
			width: 136px;
		}
	}

	.allFilter {
		width: 51px;
		@media screen and (min-width: $mobile-screen-max-width) {
			width: 62px;
		}
	}
}
