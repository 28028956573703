@import 'variables';
@import 'mixins';

.chatUserInfoLoaderContainer {
	width: $inbox-chat-user-info-max-width;

	display: grid;
	grid-template-areas: 'profilePic name' 'profilePic bio';
	grid-template-columns: auto 1fr;
	column-gap: 1.2rem;
	row-gap: 0.6rem;
	align-items: center;

	& > div {
		@include animatePulse();
		& {
			border-radius: 9999px;
			background: linear-gradient(270deg, #f0f3f5 0%, #e6eaed 100%);
		}
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		width: 100%;
		row-gap: 0.8rem;
	}
}

.imageContainer {
	grid-area: profilePic;
	height: 5.6rem;
	width: 5.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 5.2rem;
		width: 5.2rem;
	}
}

.nameContainer {
	grid-area: name;
	height: 1.6rem;
	width: 100%;
	align-self: flex-end;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 1.4rem;
		width: 70%;
	}
}

.bioContainer {
	grid-area: bio;
	height: 1.2rem;
	width: 80%;
	align-self: flex-start;

	@media screen and (max-width: $mobile-screen-max-width) {
		height: 1rem;
		width: 60%;
	}
}
