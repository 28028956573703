@import 'variables';

.commonBtnStyles {
	padding: 1.2rem;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	background: none;

	@media screen and (min-width: $mobile-screen-max-width) {
		cursor: pointer;
	}

	&:focus-visible {
		outline-color: var(--brand-secondary-color);
	}
}

.primary {
	background-color: var(--brand-primary-color);
	border-color: var(--brand-primary-color);
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: var(--brand-secondary-color);
			border-color: var(--brand-secondary-color);
		}
	}
}

.secondary {
	border: 3px solid var(--brand-primary-color);
	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			border: 3px solid var(--brand-secondary-color);
		}
	}
}

.disabled {
	pointer-events: none;
}

.disabledPrimary {
	@extend .disabled;
	background-color: var(--background-tertiary-color);
	border-color: var(--background-tertiary-color);
}

.disabledSecondary {
	@extend .disabled;
	border-color: var(--background-tertiary-color);
}
