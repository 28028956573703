@import 'variables';

.confirmViewContainer {
	flex: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: 0.8rem 2.4rem 5.6rem 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem 1.6rem 5.6rem 1.6rem;
	}

	.imagesContainer {
		display: flex;

		.remainingImagesCount {
			height: 3.2rem;
			width: 3.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--background-primary-color);
			border: 1px solid var(--border-tertiary-color);
			border-radius: 9999px;
			margin-left: -0.6rem;

			& > span {
				display: inline-block;
			}
		}

		& > span:not(:first-child) {
			margin-left: -0.6rem;
		}

		& > span {
			& > span,
			img {
				border: 1px solid var(--border-tertiary-color);
				border-radius: 9999px;
			}
		}
	}

	& > p {
		margin-top: 1.2rem;
	}

	.replyTextContainerWrapper {
		background-color: #f7f9fc;
		border-radius: 0.6rem;
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
		max-height: 100%;
		margin-top: 2rem;
		overflow: hidden;

		.replyTextContainer {
			height: 100%;
			overflow-y: auto;
			padding-left: 1.2rem;
			padding-right: 1.2rem;

			& > p {
				white-space: pre-wrap;
			}
		}
	}
}

.backBtn {
	border: 1px solid var(--border-secondary-color);

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #f5f5f5;
		}
	}
}
