@import 'variables';

.modalCustomClass {
	& > div {
		& > div:nth-child(1) {
			overflow: hidden;
			display: flex;
			flex-direction: column;
		}
	}
}

.educationContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 410px;
	position: relative;
	overflow: hidden;
	flex: 1;
	margin: auto;

	@media screen and (max-width: $mobile-screen-max-width) {
		max-width: unset;
	}
}

.closeBtn {
	padding: unset;
	padding: 5px;
	background-color: var(--background-primary-color);
	border-radius: 9999px;
	transform: rotate(45deg);

	position: absolute;
	top: 0.8rem;
	right: 0.8rem;

	.closeIcon {
		path {
			stroke: var(--text-tertiary-color);
		}
	}
}

.educationContentWrapper {
	flex: 1;
	overflow: auto;

	.imageContainer {
		background-color: #ebf5ff;
		height: 260px;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 232px;
		}
	}

	.educationContentContainer {
		padding: 2rem;
		height: 144px;

		@media screen and (max-width: $mobile-screen-max-width) {
			padding: 1.6rem;
		}

		& > h2 {
			font-size: 2rem;
			line-height: 2.8rem;
			font-weight: 600;

			@media screen and (max-width: $mobile-screen-max-width) {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}

		& > p {
			margin-top: 0.8rem;
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 400;
		}
	}
}

.educationFooterContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.6rem;
	padding-left: 2rem;
	padding-right: 2rem;
	height: 72px;
	border-top: 1px solid var(--background-secondary-color);

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem 2rem;
	}

	.dotsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.4rem;

		.dot {
			height: 6px;
			width: 6px;
			border-radius: 9999px;
			background-color: var(--border-secondary-color);
		}

		.activeDot {
			background-color: var(--text-primary-color);
		}
	}

	.backBtnDisabled {
		& > span {
			color: #e0e6eb;
		}
	}

	& > button {
		width: 88px;
		max-width: 88px;
		min-width: 88px;
		height: 32px;
		border-radius: 4px;

		& > span {
			font-weight: 600;
			font-size: 1.3rem;
			line-height: 1.8rem;
		}

		@media screen and (max-width: $mobile-screen-max-width) {
			height: 4rem;

			& > span {
				font-size: 1.4rem;
			}
		}
	}

	& > button:nth-child(1) {
		justify-content: flex-start;
	}
}
