@import 'variables';
@import 'mixins';

.chatsSection {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media screen and (min-width: $mobile-screen-max-width) {
		border-left: 1px solid var(--border-tertiary-color);
		border-right: 1px solid var(--border-tertiary-color);
	}
}

.stickyNameContainer {
	display: none;
	opacity: 0;

	height: 3.6rem;
	width: fit-content;
	max-width: calc(100% - 3.2rem);
	left: 50%;
	transform: translate(-50%, 0);
	padding: 0.8rem;
	background-color: var(--background-primary-color);
	border: 1px solid var(--border-tertiary-color);
	border-radius: 9999px;
	box-shadow: 0px 2px 8px 0px #a1a4aa3d;

	.profileImage {
		& svg,
		img {
			border-radius: 9999px;
			border: 1px solid var(--border-tertiary-color);
		}
	}

	& > h3 {
		flex: 1;
		@include textEllipsis();
	}
}

.showStickyName {
	display: block;
	position: fixed;
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	animation: fadeIn 0.25s linear 0.25s forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
