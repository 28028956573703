@import 'mixins';

.editListViewContainer {
	display: flex;
	flex-direction: column;
	max-height: 384px;
	overflow: hidden;
	background-color: var(--background-primary-color);
}

.content {
	flex: 1;
	overflow: auto;
	padding-top: 1.6rem;

	display: flex;
	flex-direction: column;
}

.cannedResponseOption {
	width: 100%;
	padding: 1.2rem 1.8rem;

	display: grid;
	grid-template-areas: 'heading iconArea' 'info iconArea';
	grid-template-columns: 1fr auto;
	column-gap: 2.2rem;
	row-gap: 0.4rem;

	&:focus {
		background-color: var(--background-secondary-color);
	}

	.iconContainer {
		grid-area: iconArea;
		align-self: flex-start;
		padding-top: 0.3rem;
	}

	.heading {
		grid-area: heading;
		font-size: 18px;
		line-height: 24px;
	}

	.info {
		grid-area: info;
		line-height: 20px;
		@include textEllipsis();
	}
}

.cannedResponseBlinkEffect {
	animation: blinkBackground 150ms ease-in-out;

	@keyframes blinkBackground {
		0% {
			background-color: inherit;
		}
		100% {
			background-color: var(--background-secondary-color);
		}
	}
}

.buttonsContainerWrapper {
	padding-top: 1.6rem;
	padding-bottom: 1.6rem;
	margin-left: 1.6rem;
	margin-right: 1.6rem;

	.buttonsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1.6rem;

		.backBtn {
			height: 4.8rem;
			width: 100%;
			padding: unset;
			border: 1px solid var(--border-secondary-color);
			border-radius: 0.8rem;
		}

		.addBtnWithIcon {
			gap: 0.8rem;
			padding: unset;
			width: 100%;
			height: 4.8rem;
			border-radius: 0.8rem;

			& > span:nth-child(1) {
				svg,
				path {
					stroke: var(--background-primary-color);
					fill: var(--background-primary-color);
				}
			}
		}
	}
}
