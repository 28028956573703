@import 'variables';
@import 'mixins';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
	}
}

.card {
	padding: 4.8rem 2.4rem;
	@include onboardingCardStyles();
	
	& > h1 {
		margin-top: 2.6rem;
		margin-bottom: 0.8rem;

		@media screen and (max-width: $mobile-screen-max-width) {
			margin-top: 2rem;
		}
	}
}

.errorText {
	margin-top: 2rem;
}

.supportText {
	margin-top: 2rem;
}

.homeBtn {
	padding: 2rem 3.2rem;
	margin-top: 3.6rem;
	border-radius: 1.2rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.2rem 3.2rem;
		margin-top: 2.4rem;
	}
}
