@import 'variables';

.onboardingLayoutContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: auto;
	background-color: var(--background-blue-secondary-color);

	@media screen and (max-width: $desktop-screen-max-width) {
		max-width: unset;
	}
}

.header {
	height: $onboarding-layout-header-desktop;
	padding: 1.2rem 8rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: unset;
		height: $onboarding-layout-header-mobile;
	}
}

.main {
	flex: 1;
	overflow: hidden;
}

.footer {
	height: $onboarding-layout-header-desktop;
	@media screen and (max-width: $mobile-screen-max-width) {
		height: $onboarding-layout-header-mobile;
	}
}
