@import 'variables';

.rootLayoutViewDesktop {
	background-color: var(--background-blue-secondary-color);
}

.messageLayout {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.mainFiltersContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1.6rem 0 1.2rem 0;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 0 1.6rem 0.8rem 0;
	}

	.searchStrContainer {
		padding: 1.2rem 2rem;
		color: var(--text-blue-tertiary-color);

		& > span {
			color: inherit;
		}
	}
}

.inboxMainLayout {
	background-color: var(--background-primary-color);
	border-top-right-radius: $inbox-main-layout-border-radius;
	border-top-left-radius: $inbox-main-layout-border-radius;
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media screen and (max-width: $mobile-screen-max-width) {
		border-radius: unset;
	}
}

.loadingContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.inboxMainChild {
	flex: 1;
	overflow: auto;
	position: relative;
	display: flex;
	overflow: hidden;
	border-top: 1px solid transparent;
}

.inboxMainChildBorder {
	border-top: 1px solid var(--border-tertiary-color);
}

.chats {
	flex: 1;
	display: none;
}

.showChats {
	display: block;
}
