@import 'variables';

.actionsContainer {
	border-top: 1px solid var(--border-tertiary-color);
	padding: 1.6rem 2.4rem 1.6rem 2.4rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		padding: 1.6rem;
	}
}

.btnsContainer {
	display: flex;
	width: 100%;
	gap: 1.6rem;
	margin-top: 1.6rem;

	@media screen and (max-width: $mobile-screen-max-width) {
		gap: 0.8rem;
	}

	& > button {
		width: 100%;
		height: 4rem;
		padding: unset;
		border-radius: 0.4rem;
	}
}
