@import 'variables';
@import 'mixins';

.pageContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: auto;
	width: 100%;

	@include disableUserSelect();
}

.pageContainerMobile {
	max-width: $inbox-chat-user-info-max-width;
	width: 100%;
	@media screen and (max-width: $mobile-screen-max-width) {
		width: 0;
	}
}

.borderEle {
	height: 1px;
	width: 100%;
	top: 0;
	position: sticky;
}

.addBackgroundColor {
	background-color: var(--border-tertiary-color);
}

.chatRow {
	display: flex;
	align-items: center;
	position: relative;

	@media screen and (max-width: $mobile-screen-max-width) {
		&:nth-child(1) {
			& > article {
				padding-top: 2rem;
			}
		}
	}

	@media screen and (min-width: $mobile-screen-max-width) {
		&:hover {
			background-color: #f9f9f9;
			cursor: pointer;
		}
	}
}

.label {
	background-color: #e5f2ff;
	padding: 0.4rem 1.2rem;
	border-radius: 999px;
	color: #488cd1;
	margin-right: 1.2rem;
}

.lastMessage {
	flex: 1;
	padding: 1.2rem 1.6rem;
	@include textEllipsis();

	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}
}

.dateDisplay {
	padding-left: 1.6rem;
	padding-right: 3.2rem;
	@media screen and (max-width: $mobile-screen-max-width) {
		display: none;
	}
}

.loadingContainer {
	& > p {
		text-align: center;
		padding: 2.4rem;
	}
}

.chatReported {
	height: 0px;
	overflow: hidden;
}
